import React from "react";
import StarRatings from "react-star-ratings";
import { BsFolderSymlink, BsPerson } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import ReactHlsPlayer from "react-hls-player";
import intApi from "../../../api/int_api";
import ImageView from "../../../component/ImageView";
import MeetingChat from "./MeetingChat";
import S from "../../../Services/Services";

const ViewTechFeedback: React.FC = () => {
  const [selectOption, setSelectOption] = React.useState("Candidate Details");
  const [defaultPage, setDefaultPage] = React.useState("Primary Skills");
  const [schedule, setSchedule] = React.useState({} as any);
  const [templateData, setTemplateData] = React.useState({} as any);
  const [openImageFullView, setOpenImageFullView] = React.useState(false);
  const { schedule_id } = useParams();
  const [chat, setChat] = React.useState([] as any);
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const changePage = (page: any) => setDefaultPage(page);
  const navigate = useNavigate();

  React.useEffect(() => {
    getFeedback();
  }, []);

  const getChat = async () => {
    if (chat.length === 0) {
      const res = await intApi.getChat(schedule_id);
      setChat(res.data.message);
    }
  };

  const getFeedback = async () => {
    const res = await intApi.getFeedback(schedule_id);
    setSchedule(res.data.schedule);
    getTemplateData(res.data.schedule.post.template_id);
  };
  const getTemplateData = async (template_id: any) => {
    const res = await intApi.getTemplateData(template_id);
    setTemplateData(res.data.template);
  };
  const options = [
    "Candidate Details",
    "Job Details",
    "Interview Details",
    "Technical Skills",
    "Non-Technical Skills",
    "Interview Video",
    // "Transcript",
    "Programming Code",
    "Recommendation",
  ];
  const listItems = options.map((opt) => (
    <p
      onClick={() => {
        setSelectOption(opt);
        if (opt === "Transcript") {
          getChat();
        }
      }}
      className={
        selectOption === opt
          ? " flex items-center text-white text-sm font-semibold py-3 px-5 cursor-pointer bg-primary-tint"
          : " flex items-center text-white text-sm font-semibold py-3 px-5 cursor-pointer"
      }
    >
      {opt}
    </p>
  ));
  const getSkills = (skills: any) => {
    var skillsString = "";
    skillsString = Array.prototype.map
      .call(skills, function (item) {
        return item["skill"]["name"];
      })
      .join(",");
    return skillsString;
  };
  const timezoneList = [
    { id: 0, name: "IST", fullName: "Asia/Kolkata" },
    { id: 1, name: "IST", fullName: "Asia/Calcutta" },
    { id: 2, name: "EST", fullName: "America/New_York" },
    { id: 3, name: "GMT", fullName: "Europe/London" },
    { id: 4, name: "CST", fullName: "America/Chicago" },
    { id: 5, name: "PST", fullName: "America/Los_Angeles" },
    { id: 6, name: "HST", fullName: "Pacific/Honolulu" },
    { id: 7, name: "AHST", fullName: "America/Anchorage" },
  ];
  const getTz = (tz: any) => {
    var timezonetype = "";
    for (let i = 0; i < timezoneList.length; i++) {
      if (timezoneList[i].fullName === tz) {
        timezonetype = timezoneList[i].name;
      }
    }
    return timezonetype;
  };

  const gotoDownloadFeedback = () => {
    navigate("/download_tech_feedback", { state: { schedule: schedule } });
  };

  return (
    <>
      {openImageFullView && (
        <ImageView
          imgSrc={schedule["feedback"][0]["url"]}
          close={setOpenImageFullView}
        ></ImageView>
      )}
      {Object.keys(schedule).length !== 0 && (
        <div className="flex justify-center bg-gray-50 w-full">
          <div className="w-3/4 bg-white py-6">
            <div className="flex justify-between items-left max-h-screen overflow-hidden px-5">
              <div className="">
                <h1 className="text-md font-semibold text-black py-2">
                  Interview Evaluation Sheet - {schedule.post.job_title}
                </h1>
              </div>
              <div className="">
                <button
                  onClick={() => gotoDownloadFeedback()}
                  className="flex items-center px-3 py-2 text-primary  text-sm font-semibold border  border-primary rounded-md mb-3 ml-2"
                >
                  <BsFolderSymlink />
                  &nbsp;&nbsp;Download pdf
                </button>
              </div>
            </div>
            <div className="w-full border-b py-1"></div>
            <div className="grid grid-cols-4 py-5 px-5">
              <div className="col-span-1 mr-2">
                <div className="bg-primary border-primary rounded-lg w-full py-5">
                  {listItems}
                </div>
              </div>
              {/* <div className="h-auto border-l-grey py-0.5"></div> */}
              <div
                className="col-span-3 bg-gray-50 overflow-y-auto rounded-lg px-10 pt-3"
                style={{ height: "75vh" }}
              >
                {selectOption === "Candidate Details" && (
                  <>
                    <div className="py-10">
                      {schedule["feedback"][0]["url"] === null ||
                      schedule["feedback"][0]["url"] === "" ||
                      schedule["feedback"][0]["url"] === undefined ? (
                        <div className="h-32 w-32 bg-primary rounded-full overflow-hidden ">
                          <div className="flex justify-center items-center h-32">
                            <BsPerson color="white" size={70} />
                          </div>
                        </div>
                      ) : (
                        <div className="h-32 w-32 mr-1 bg-gray-100 rounded-full overflow-hidden">
                          <img
                            src={schedule["feedback"][0]["url"]}
                            alt="user_profile_photo"
                            className="h-full w-full object-cover cursor-pointer"
                            onClick={() => setOpenImageFullView(true)}
                          ></img>
                        </div>
                      )}
                    </div>
                    <div className="grid grid-cols-4 gap-5">
                      <h1 className="text-xs font-bold text-black  col-span-1">
                        Name
                      </h1>
                      <h1 className="text-xs font-thin text-black col-span-3">
                        {schedule["candidate"]["first_name"]}{" "}
                        {schedule.candidate.last_name}
                      </h1>
                      <h1 className="text-xs font-bold text-black col-span-1">
                        Contact
                      </h1>
                      <h1 className="text-xs font-thin text-black  col-span-3">
                        {schedule.candidate.mobile}
                      </h1>
                      <h1 className="text-xs font-bold text-black  col-span-1">
                        Email-ID
                      </h1>
                      <h1 className="text-xs font-thin text-black  col-span-3">
                        {schedule.candidate.email}
                      </h1>
                      <h1 className="text-xs font-bold text-black  col-span-1">
                        Resume
                      </h1>
                      <h1 className="text-xs font-thin text-black col-span-3">
                        <a
                          target={schedule.candidate.resume[0].url}
                          className="text-blue-500 text-xs font-semibold underline cursor-pointer"
                          href={schedule.candidate.resume[0].url}
                        >
                          Link
                        </a>
                      </h1>
                    </div>
                  </>
                )}
                {selectOption === "Job Details" && (
                  <div className="grid grid-cols-4 gap-5  mt-5">
                    <h1 className="text-xs font-bold text-black col-span-1">
                      Job Title
                    </h1>
                    <h1 className="text-xs font-thin text-grey-500 col-span-3">
                      {schedule.post.job_title}
                    </h1>
                    <h1 className="text-xs font-bold text-black col-span-1">
                      Primary Skills
                    </h1>
                    <h1 className="text-xs font-thin text-grey-500 col-span-3">
                      {getSkills(templateData.t_p_skill)}
                    </h1>
                    <h1 className="text-xs font-bold text-black col-span-1">
                      Secondary Skills
                    </h1>
                    <h1 className="text-xs font-thin text-grey-500 col-span-3">
                      {getSkills(templateData.t_s_skill)}
                    </h1>
                    <h1 className="text-xs font-bold text-black col-span-1">
                      Experience
                    </h1>
                    <h1 className="text-xs font-thin text-grey-500 col-span-3">
                      {schedule.post.year_of_experience} {" Years "}
                      {schedule.post.month_of_experience} {" Months"}
                    </h1>
                    <h1 className="text-xs font-bold text-black col-span-1">
                      Job Description
                    </h1>
                    <h1 className="text-xs font-thin text-grey-500 col-span-3">
                      {schedule.post.job_comments}
                    </h1>
                    <h1 className="text-xs font-bold text-black col-span-1">
                      Remarks
                    </h1>
                    <h1 className="text-xs font-thin text-grey-500 col-span-3">
                      {schedule.post.remarks}
                    </h1>
                    <h1 className="text-xs font-bold text-black col-span-1">
                      JD Attachments
                    </h1>
                    <h1 className="text-xs font-thin text-black col-span-3">
                      <a
                        target={templateData.attachment}
                        className="text-blue-500 text-xs font-semibold underline cursor-pointer"
                        href={templateData.attachment}
                      >
                        Link
                      </a>
                    </h1>
                  </div>
                )}
                {selectOption === "Interview Details" && (
                  <div className="grid grid-cols-4 gap-5  mt-5">
                    <h1 className="text-xs font-bold text-black col-span-1">
                      Interviewer Name
                    </h1>
                    <h1 className="text-xs font-thin text-black col-span-3">
                      {schedule.interviewer.candidate.first_name}
                    </h1>
                    <h1 className="text-xs font-bold text-black col-span-1">
                      Date of the Interview
                    </h1>
                    <h1 className="text-xs font-thin text-black col-span-3">
                      {format(schedule.utc_starttime, "dd MMM yyyy")}
                    </h1>
                    <h1 className="text-xs font-bold text-black col-span-1">
                      Time of the Interview
                    </h1>
                    <h1 className="text-xs font-thin text-black col-span-3">
                      {format(schedule.utc_starttime, "hh:mm a")} to{" "}
                      {format(schedule.utc_endtime, "hh:mm a")}{" "}
                      {getTz(schedule.candidate_tz)}
                    </h1>
                    <h1 className="text-xs font-bold text-black col-span-1">
                      Mode of Interview
                    </h1>
                    <h1 className="text-xs font-thin text-black col-span-3">
                      {schedule.post.interview_type}
                    </h1>
                    <h1 className="text-xs font-bold text-black col-span-1">
                      Interview Duration
                    </h1>
                    <h1 className="text-xs font-thin text-black col-span-3">
                      {schedule.interview_duration}
                    </h1>
                    <h1 className="text-xs font-bold text-black col-span-1">
                      Candidate No Show
                    </h1>
                    <h1 className="text-xs font-thin text-black col-span-3">
                      <div className="flex justify-left gap-2">
                        <div className="flex justify-start item-center gap-2">
                          <input
                            type="radio"
                            name="candidate_noshow"
                            id="candidate_noshow"
                            value="yes"
                            checked={schedule.feedback[0].candidate_no_show}
                          />
                          <h1 className="text-black text-xs font-bold">Yes</h1>
                        </div>
                        <div className="flex justify-start item-center gap-2">
                          <input
                            type="radio"
                            name="candidate_noshow"
                            id="candidate_noshow"
                            value="no"
                            checked={!schedule.feedback[0].candidate_no_show}
                          />
                          <h1 className="text-black text-xs font-bold">No</h1>
                        </div>
                      </div>
                    </h1>
                    <h1 className="text-xs font-bold text-black col-span-1">
                      Candidate Proxy
                    </h1>
                    <h1 className="text-xs font-thin text-black col-span-3">
                      {" "}
                      <div className="flex justify-left gap-2">
                        <div className="flex justify-start item-center gap-2">
                          <input
                            type="radio"
                            name="candidate_proxy"
                            id="candidate_proxy"
                            value="yes"
                            checked={schedule.feedback[0].proxy}
                          />
                          <h1 className="text-black text-xs font-bold">Yes</h1>
                        </div>
                        <div className="flex justify-start item-center gap-2">
                          <input
                            type="radio"
                            name="candidate_proxy"
                            id="candidate_proxy"
                            value="no"
                            checked={!schedule.feedback[0].proxy}
                          />
                          <h1 className="text-black text-xs font-bold">No</h1>
                        </div>
                      </div>
                    </h1>
                    <h1 className="text-xs font-bold text-black col-span-1">
                      Window Violation
                    </h1>
                    <h1 className="text-xs font-thin text-black col-span-3">
                      <div className="flex justify-left gap-2">
                        <div className="flex justify-start item-center gap-2">
                          <input
                            type="radio"
                            name="window_violation"
                            id="window_violation"
                            value="yes"
                            checked={schedule.feedback[0].window_violation}
                          />
                          <h1 className="text-black text-xs font-bold">Yes</h1>
                        </div>
                        <div className="flex justify-start item-center gap-2">
                          <input
                            type="radio"
                            name="window_violation"
                            id="window_violation"
                            value="no"
                            checked={!schedule.feedback[0].window_violation}
                          />
                          <h1 className="text-black text-xs font-bold">No</h1>
                        </div>
                      </div>
                    </h1>
                    <h1 className="text-xs font-bold text-black col-span-1">
                      Interview Status
                    </h1>
                    <h1
                      className={
                        schedule.feedback[0].interview_status === "Completed"
                          ? "text-xs font-bold text-primary col-span-3"
                          : "text-xs font-bold text-red-500 col-span-3"
                      }
                    >
                      {schedule.feedback[0].interview_status}
                    </h1>
                    {schedule.feedback[0].interview_status !== "Completed" && (
                      <>
                        <h1 className="text-xs font-bold text-black col-span-1">
                          Reason
                        </h1>
                        <h1 className="text-xs font-thin text-black col-span-3">
                          {schedule.feedback[0].incomplete_reason_cmt}
                        </h1>
                      </>
                    )}
                    {schedule.feedback[0].comments !== "" &&
                      schedule.feedback[0].comments !== null && (
                        <>
                          <h1 className="text-xs font-bold text-black col-span-1">
                            Comments
                          </h1>
                          <h1 className="text-xs font-thin text-black col-span-3">
                            {schedule.feedback[0].comments}
                          </h1>
                        </>
                      )}
                  </div>
                )}
                {selectOption === "Technical Skills" && (
                  <>
                    <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 ">
                      <ul
                        className="flex justify-evenly -mb-px"
                        id="default-tab"
                        data-tabs-toggle="#default-tab-content"
                        role="tablist"
                      >
                        <li className="">
                          <a
                            onClick={() => changePage("Primary Skills")}
                            className={
                              defaultPage === "Primary Skills"
                                ? "inline-flex items-center justify-center  p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                                : "inline-flex items-center justify-center  p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                            }
                          >
                            Required Technical Skills
                          </a>
                        </li>
                        <li className="">
                          <a
                            onClick={() => changePage("Secondary Skills")}
                            className={
                              defaultPage === "Secondary Skills"
                                ? "inline-flex items-center justify-center  p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                                : "inline-flex items-center justify-center  p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                            }
                          >
                            Preferred Technical Skills
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div id="default-tab-content ">
                      {defaultPage === "Primary Skills" && (
                        <>
                          {schedule.feedback[0].fp_skill.map((x: any) => (
                            <div className="px-10 py-5">
                              <div className=" text-sm font-bold mb-3">
                                {x.skill.name}
                              </div>
                              <div className="flex justify-center">
                                <table className="table-fixed w-full">
                                  <tbody className="text-gray-600 text-xs">
                                    <tr className="bg-gray-50 border-b-2 border-white ">
                                      <td>
                                        <p className="p-2">Rating</p>
                                      </td>
                                      <td>
                                        <p className="p-2">
                                          <StarRatings
                                            rating={x.skill_rating}
                                            changeRating={(rate: number) =>
                                              console.log(rate)
                                            }
                                            numberOfStars={5}
                                            name="rating"
                                            starRatedColor="orange"
                                            starHoverColor="orange"
                                            starDimension="15px"
                                            starSpacing="2px"
                                          />
                                        </p>
                                      </td>
                                    </tr>
                                    <tr className="bg-gray-50 border-b-2 border-white ">
                                      <td>
                                        <p className="p-2">Experience</p>
                                      </td>
                                      <td>
                                        <p className="p-2">{x.experience}</p>
                                      </td>
                                    </tr>
                                    <tr className="bg-gray-50 border-b-2 border-white ">
                                      <td>
                                        <p className="p-2">
                                          Technical Concepts
                                        </p>
                                      </td>
                                      <td>
                                        <p className="p-2">
                                          {x.program_concept}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr className="bg-gray-50 border-b-2 border-white ">
                                      <td>
                                        <p className="p-2">Logic</p>
                                      </td>
                                      <td>
                                        <p className="p-2">{x.logics}</p>
                                      </td>
                                    </tr>
                                    <tr className="bg-gray-50 border-b-2 border-white ">
                                      <td>
                                        <p className="p-2">Development Tools</p>
                                      </td>
                                      <td>
                                        <p className="p-2">
                                          {x.development_tools}
                                        </p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                      {defaultPage === "Secondary Skills" && (
                        <>
                          {schedule.feedback[0].fs_skill.map((x: any) => (
                            <div className="px-10 py-5">
                              <div className=" text-sm font-bold mb-3">
                                {x.skill.name}
                              </div>
                              <div className="flex justify-center">
                                <table className="table-fixed w-full">
                                  <tbody className="text-gray-600 text-xs">
                                    <tr className="bg-gray-50 border-b-2 border-white ">
                                      <td>
                                        <p className="p-2">Rating</p>
                                      </td>
                                      <td>
                                        <p className="p-2">
                                          <StarRatings
                                            rating={x.skill_rating}
                                            changeRating={(rate: number) =>
                                              console.log(rate)
                                            }
                                            numberOfStars={5}
                                            name="rating"
                                            starRatedColor="orange"
                                            starHoverColor="orange"
                                            starDimension="15px"
                                            starSpacing="2px"
                                          />
                                        </p>
                                      </td>
                                    </tr>
                                    <tr className="bg-gray-50 border-b-2 border-white ">
                                      <td>
                                        <p className="p-2">Experience</p>
                                      </td>
                                      <td>
                                        <p className="p-2">{x.experience}</p>
                                      </td>
                                    </tr>
                                    <tr className="bg-gray-50 border-b-2 border-white ">
                                      <td>
                                        <p className="p-2">
                                          Technical Concepts
                                        </p>
                                      </td>
                                      <td>
                                        <p className="p-2">
                                          {x.program_concept}
                                        </p>
                                      </td>
                                    </tr>
                                    <tr className="bg-gray-50 border-b-2 border-white ">
                                      <td>
                                        <p className="p-2">Logic</p>
                                      </td>
                                      <td>
                                        <p className="p-2">{x.logics}</p>
                                      </td>
                                    </tr>
                                    <tr className="bg-gray-50 border-b-2 border-white ">
                                      <td>
                                        <p className="p-2">Development Tools</p>
                                      </td>
                                      <td>
                                        <p className="p-2">
                                          {x.development_tools}
                                        </p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </>
                )}
                {selectOption === "Non-Technical Skills" && (
                  <div className="grid grid-cols-2 gap-3 py-5">
                    <div>
                      <p className="text-xs text-black font-thin">
                        Communication Level
                      </p>
                      <p className="text-xs text-gray-900 font-semibold truncate pt-2 pb-5">
                        <StarRatings
                          rating={schedule.feedback[0].communication_skill}
                          changeRating={(rate: number) => console.log(rate)}
                          numberOfStars={5}
                          name="rating"
                          starRatedColor="orange"
                          starHoverColor="orange"
                          starDimension="18px"
                          starSpacing="3px"
                        />
                      </p>
                    </div>
                    <div>
                      <p className="text-xs text-black font-thin">Integrity</p>
                      <p className="text-xs text-gray-900 font-semibold truncate pt-2 pb-5">
                        <StarRatings
                          rating={schedule.feedback[0].intrigity}
                          changeRating={(rate: number) => console.log(rate)}
                          numberOfStars={5}
                          name="rating"
                          starRatedColor="orange"
                          starHoverColor="orange"
                          starDimension="18px"
                          starSpacing="3px"
                        />
                      </p>
                    </div>
                    <div>
                      <p className="text-xs text-black font-thin">
                        Stress Tolerance
                      </p>
                      <p className="text-xs text-gray-900 font-semibold truncate pt-2 pb-5">
                        <StarRatings
                          rating={schedule.feedback[0].stress_tolerance}
                          changeRating={(rate: number) => console.log(rate)}
                          numberOfStars={5}
                          name="rating"
                          starRatedColor="orange"
                          starHoverColor="orange"
                          starDimension="18px"
                          starSpacing="3px"
                        />
                      </p>
                    </div>
                    <div>
                      <p className="text-xs text-black font-thin">
                        Attention to Details
                      </p>
                      <p className="text-xs text-gray-900 font-semibold truncate pt-2 pb-5">
                        <StarRatings
                          rating={schedule.feedback[0].attention}
                          changeRating={(rate: number) => {
                            console.log(rate);
                          }}
                          numberOfStars={5}
                          name="rating"
                          starRatedColor="orange"
                          starHoverColor="orange"
                          starDimension="18px"
                          starSpacing="3px"
                        />
                      </p>
                    </div>
                    <div>
                      <p className="text-xs text-black font-thin">
                        Cooperation
                      </p>
                      <p className="text-xs text-gray-900 font-semibold truncate pt-2 pb-5">
                        <StarRatings
                          rating={schedule.feedback[0].co_operation}
                          changeRating={(rate: number) => {
                            console.log(rate);
                          }}
                          numberOfStars={5}
                          name="rating"
                          starRatedColor="orange"
                          starHoverColor="orange"
                          starDimension="18px"
                          starSpacing="3px"
                        />
                      </p>
                    </div>
                    <div>
                      <p className="text-xs text-black font-thin">
                        Learning Ability
                      </p>
                      <p className="text-xs text-gray-900 font-semibold truncate pt-2 pb-5">
                        <StarRatings
                          rating={schedule.feedback[0].learning_ability}
                          changeRating={(rate: number) => {
                            console.log(rate);
                          }}
                          numberOfStars={5}
                          name="rating"
                          starRatedColor="orange"
                          starHoverColor="orange"
                          starDimension="18px"
                          starSpacing="3px"
                        />
                      </p>
                    </div>
                  </div>
                )}
                {selectOption === "Interview Video" && (
                  <h1 className="text-md font-bold text-black py-10">
                    {S.getFileExtension(schedule.video_url) !== "mp4" ? (
                      <ReactHlsPlayer
                        src={schedule.video_url}
                        autoPlay={false}
                        controls={true}
                        width="750"
                        height="500"
                      />
                    ) : (
                      <video
                        src={schedule.video_url}
                        autoPlay={false}
                        controls={true}
                        width="750"
                        height="500"
                      />
                    )}
                  </h1>
                )}
                {selectOption === "Transcript" && (
                  <>
                    <MeetingChat chat={chat} />
                  </>
                )}
                {selectOption === "Programming Code" && (
                  <>
                    {schedule.code.map((x: any) => (
                      <>
                        <h1 className="text-sm font-semibold text-gray-900 pt-5">
                          {x.title}
                        </h1>
                        <pre className="text-sm font-normal text-gray-400 py-2">
                          {x.code}
                        </pre>
                      </>
                    ))}
                  </>
                )}
                {selectOption === "Recommendation" && (
                  <div className="grid grid-cols-5 gap-5 mt-5">
                    <h1 className="text-xs font-bold text-black col-span-2">
                      Interviewer Comments
                    </h1>
                    <h1 className="text-xs font-thin text-black col-span-3">
                      {schedule.feedback[0].comments}
                    </h1>
                    <h1 className="text-xs font-bold text-black col-span-2">
                      ScreenIt Score
                    </h1>
                    <div className="flex flex-row gap-1 col-span-3">
                      {numbers.map((number) => (
                        <button
                          type="button"
                          className={
                            number <= schedule["feedback"][0]["sit_score"]
                              ? "flex items-center justify-center w-12 h-9 text-white text-sm font-semibold button-primary border rounded-md mb-3 "
                              : "flex items-center justify-center w-12 h-9 text-white text-sm font-semibold bg-gray-200 border rounded-md mb-3 "
                          }
                        >
                          {number}
                        </button>
                      ))}
                    </div>
                    <h1 className="text-xs font-bold text-black col-span-2">
                      Recommended
                    </h1>
                    <h1 className="text-xs font-semibold text-primary col-span-3">
                      {schedule.feedback[0].recommended_status}
                    </h1>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ViewTechFeedback;
