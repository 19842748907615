import React from "react";
import { Style } from "../../../utils/Style";
import { useParams } from "react-router-dom";
import intApi from "../../../api/int_api";
import { format } from "date-fns";
import { BsFolderSymlink, BsPerson } from "react-icons/bs";
import html2pdf from "html2pdf.js";
import ImageView from "../../../component/ImageView";
import StarRatingComp from "../../../component/StarRatingCom";
import ReactHlsPlayer from "react-hls-player";
import S from "../../../Services/Services";

// import { feedbackUrl } from "../../../api/basr_url";

const ViewTechFeedbackNamIT: React.FC = () => {
  const [scheduleDetail, setSchedule] = React.useState({} as any);
  const { schedule_id } = useParams();
  const [openImageFullView, setOpenImageFullView] = React.useState(false);
  const [starState, setStarState] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [base64Img, setBase64Img] = React.useState<string | null>(null);

  React.useEffect(() => {
    setTimeout(() => {
      setStarState(true);
      console.log(starState);
    }, 1000);
  }, []);

  React.useEffect(() => {
    getFeedback();
  }, []);

  const getFeedback = async () => {
    const res = await intApi.getFeedback(schedule_id);
    setSelectedImage(res.data.schedule["feedback"][0]["url"]);
    await getProfileURL(res.data.schedule["feedback"][0]["url"]);
    setSchedule(res.data.schedule);
  };

  const getDate = (dateStr: any) => {
    const formattedDate = format(dateStr, "dd MMM yyyy");
    return formattedDate;
  };

  const downloadPDF = async () => {
    var viewchat = document.getElementById("viewchat");
    if (viewchat) {
      viewchat.style.display = "none";
    }
    const element = document.getElementById("pdfdocument");
    const options = {
      margin: 5,
      // filename: "feedback.pdf",
      filename: `${scheduleDetail["candidate"]["first_name"]}-${scheduleDetail.post.job_title}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 3 },
      pagebreak: { avoid: [".avoid", ".text-xs", ".text-sm"] },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    await html2pdf().from(element).set(options).save();
    if (viewchat) {
      viewchat.style.display = "block";
    }
  };

  const getProfileURL = async (url: any) => {
    console.log("selectedImage", selectedImage);
    await urlToBase64(url!).then(async (base64Url: any) => {
      setBase64Img(base64Url);
      setTimeout(() => {
        setStarState(true);
      }, 500);
    });
  };

  async function urlToBase64(url: string) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.statusText}`);
      }
      const blob = await response.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
      });
    } catch (error) {
      console.error("Error converting URL to base64:", error);
      return null;
    }
  }

  // const openMeetingChat = () => {
  //   window.open(feedbackUrl + "/view_chat/" + schedule_id, "_blank");
  // };
  return (
    <>
      {openImageFullView && (
        <ImageView
          imgSrc={scheduleDetail["feedback"][0]["url"]}
          close={setOpenImageFullView}
        ></ImageView>
      )}

      {Object.keys(scheduleDetail).length !== 0 && (
        <div className="flex justify-center bg-gray-100 w-full min-h-screen">
          <div className="w-3/5 bg-white">
            <div className="py-2 flex justify-end items-center">
              <button
                onClick={() => downloadPDF()}
                className="flex items-center px-3 py-2 text-primary  text-sm font-semibold border  border-primary rounded-md mb-3 mx-2"
              >
                <BsFolderSymlink />
                &nbsp;&nbsp;Download pdf
              </button>
            </div>
            <div id="pdfdocument" className="pl-5 pr-5">
              <div className="flex justify-center items-center max-h-screen overflow-hidden border-b">
                <div className="pb-3">
                  <h1 className="text-sm font-semibold">
                    Interactive Technical Assessment
                  </h1>
                </div>
              </div>
              <div className="grid grid-cols-3 bg-primary border-primary p-4 gap-3 rounded-tl-lg rounded-br-lg">
                <div className="">
                  <h1 className="text-gray-200 text-xs font-light ">
                    Candidate Name
                  </h1>
                  <h1 className="text-white text-xs font-bold">
                    {scheduleDetail["candidate"]["first_name"]}{" "}
                    {scheduleDetail["candidate"]["last_name"] &&
                      scheduleDetail["candidate"]["last_name"]}
                  </h1>
                </div>
                <div className="">
                  <h1 className="text-gray-200 text-xs font-light">
                    Panelist's Name
                  </h1>
                  <h1 className="text-white text-xs font-bold">
                    {scheduleDetail.interviewer.candidate.first_name}{" "}
                    {scheduleDetail.interviewer.candidate.last_name &&
                      scheduleDetail.interviewer.candidate.last_name}
                  </h1>
                </div>
                <div className="">
                  <h1 className="text-gray-200 text-xs font-light">
                    Recruiter's Name:
                  </h1>
                  <h1 className="text-white text-xs font-bold">
                    {scheduleDetail.post.org.first_name}{" "}
                    {scheduleDetail.post.org.last_name}
                  </h1>
                </div>
                <div className="">
                  <h1 className="text-gray-200 text-xs font-light">Role</h1>
                  <h1 className="text-white text-xs font-bold">
                    {scheduleDetail.post.job_title}
                  </h1>
                </div>
                <div className="">
                  <h1 className="text-gray-200 text-xs font-light">
                    Date(DD MMM YYYY):
                  </h1>
                  <h1 className="text-white text-xs font-bold">
                    {getDate(scheduleDetail.utc_starttime)}
                  </h1>
                </div>
                <div className="">
                  <h1 className="text-gray-200 text-xs font-light">
                    Name of the Client:
                  </h1>
                  <h1 className="text-white text-xs font-bold">
                    {scheduleDetail.post.client_name !== ""
                      ? scheduleDetail.post.client_name
                      : "NA"}
                  </h1>
                </div>
              </div>
              <form>
                <div className="flex justify-between items-center my-3 grid grid-cols-2 gap-3">
                  <div className="flex justify-start items-center gap-4">
                    <h1 className="text-xs font-normal text-gray-500">
                      Candidate No Show
                    </h1>
                    <div className="flex justify-left gap-2">
                      <div className="flex justify-start item-center gap-1">
                        <input
                          type="radio"
                          name="candidateNoShow"
                          value="yes"
                          checked={scheduleDetail.feedback[0].candidate_no_show}
                        />
                        <h1 className="text-black text-xs font-bold">Yes</h1>
                      </div>
                      <div className="flex justify-start item-center gap-1">
                        <input
                          type="radio"
                          name="candidateNoShow"
                          value="no"
                          checked={
                            !scheduleDetail.feedback[0].candidate_no_show
                          }
                        />
                        <h1 className="text-black text-xs font-bold">No</h1>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-start items-center gap-4">
                    <h1 className="text-xs font-normal text-gray-500">
                      Candidate Proxy
                    </h1>
                    <div className="flex justify-left gap-2">
                      <div className="flex justify-start item-center gap-1">
                        <input
                          type="radio"
                          name="candidateProxy"
                          value="yes"
                          checked={scheduleDetail.feedback[0].proxy}
                        />
                        <h1 className="text-black text-xs font-bold">Yes</h1>
                      </div>
                      <div className="flex justify-start item-center gap-1">
                        <input
                          type="radio"
                          name="candidateProxy"
                          value="no"
                          checked={!scheduleDetail.feedback[0].proxy}
                        />
                        <h1 className="text-black text-xs font-bold">No</h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center my-3 grid grid-cols-2 gap-3">
                  <div className="flex justify-start items-center gap-4">
                    <h1 className="text-xs font-normal text-gray-500">
                      Mode of Interview
                    </h1>
                    <div className="flex justify-left gap-2">
                      <select
                        disabled
                        id="interview_mode"
                        name="interview_mode"
                        value={
                          scheduleDetail.feedback[0].mode &&
                          scheduleDetail.feedback[0].mode
                        }
                        className={Style.iInput}
                      >
                        <option value="" disabled>
                          <h1 className="text-sm font-thin text-slate-800">
                            Interview
                          </h1>
                        </option>
                        <option value="Zoom">
                          <h1 className="text-sm font-thin text-slate-800">
                            Zoom
                          </h1>
                        </option>
                        <option value="WebEx">
                          <h1 className="text-sm font-thin text-slate-800">
                            WebEx
                          </h1>
                        </option>
                        <option value="In Person">
                          <h1 className="text-sm font-thin text-slate-800">
                            In Person
                          </h1>
                        </option>
                        <option value="Hangout Teams">
                          <h1 className="text-sm font-thin text-slate-800">
                            Hangout Teams
                          </h1>
                        </option>
                        <option value="Skype">
                          <h1 className="text-sm font-thin text-slate-800">
                            Skype
                          </h1>
                        </option>
                        <option value="Microsoft Teams">
                          <h1 className="text-sm font-thin text-slate-800">
                            Microsoft Teams
                          </h1>
                        </option>
                        <option value="ScreenIt">
                          <h1 className="text-sm font-thin text-slate-800">
                            ScreenIt
                          </h1>
                        </option>
                      </select>
                    </div>
                  </div>
                  <div
                    className={
                      scheduleDetail.feedback[0].candidate_no_show ||
                      scheduleDetail.feedback[0].proxy
                        ? "disabled-div flex justify-start items-center gap-4"
                        : "flex justify-start items-center gap-4"
                    }
                  >
                    <h1 className="text-xs font-normal text-gray-500">
                      Level of Interview
                    </h1>
                    <div className="flex justify-left gap-2">
                      <select
                        disabled
                        id="interview_level"
                        name="interview_level"
                        value={
                          scheduleDetail.feedback[0].level &&
                          scheduleDetail.feedback[0].level
                        }
                        className={Style.iInput}
                      >
                        <option value="" disabled>
                          <h1 className="text-sm font-thin text-slate-800">
                            Select Level
                          </h1>
                        </option>
                        <option value="Junior">
                          <h1 className="text-sm font-thin text-slate-800">
                            Junior
                          </h1>
                        </option>
                        <option value="Mid">
                          <h1 className="text-sm font-thin text-slate-800">
                            Mid
                          </h1>
                        </option>
                        <option value="Senior">
                          <h1 className="text-sm font-thin text-slate-800">
                            Senior
                          </h1>
                        </option>
                        <option value="Lead">
                          <h1 className="text-sm font-thin text-slate-800">
                            Lead
                          </h1>
                        </option>
                        <option value="Architect">
                          <h1 className="text-sm font-thin text-slate-800">
                            Architect
                          </h1>
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    scheduleDetail.feedback[0].candidate_no_show ||
                    scheduleDetail.feedback[0].proxy
                      ? "disabled-div flex flex-col items-start gap-2 my-3 w-full"
                      : "flex flex-col items-start gap-2 my-3 w-full"
                  }
                >
                  <p className="font-semibold text-xs underline text-gray-900">
                    Scoring Definitions
                  </p>
                  <div className="grid grid-cols-11 flex items-center justify-start ml-6 text-xs font-semibold text-gray-600 gap-1">
                    <p className="col-span-3 w-full">1 to 1.5</p>
                    <p className="col-span-1 w-full">-</p>
                    <p className="col-span-7 w-full">
                      Lacks overall competency, needs improvement (Rejected)
                    </p>
                    <p className="col-span-3 w-full">2 to 2.5</p>
                    <p className="col-span-1 w-full">-</p>
                    <p className="col-span-7 w-full">
                      Limited competency, scalable in shorter term (Junior
                      level)
                    </p>

                    <p className="col-span-3 w-full">3 to 3.5</p>
                    <p className="col-span-1 w-full">-</p>
                    <p className="col-span-7 w-full">
                      Appreciable and broader competency, rapidly scalable (Mid
                      / Senior level)
                    </p>

                    <p className="col-span-3 w-full">4 to 4.5</p>
                    <p className="col-span-1 w-full">-</p>
                    <p className="col-span-7 w-full">
                      Demonstrated competency, consistently adaptive (Lead /
                      Architect level)
                    </p>

                    <p className="col-span-3 w-full">5</p>
                    <p className="col-span-1 w-full">-</p>
                    <p className="col-span-7 w-full">
                      Proficient competency, and progressive (SME level)
                    </p>
                  </div>
                </div>
                <div
                  className={
                    scheduleDetail.feedback[0].candidate_no_show ||
                    scheduleDetail.feedback[0].proxy
                      ? "disabled-div flex flex-col items-start gap-2 my-3 w-full"
                      : "flex flex-col items-start gap-2 my-3 w-full"
                  }
                >
                  <div className="pt-2">
                    <h1 className="text-sm font-semibold text-gray-800 mb-2">
                      Technical Evaluation
                    </h1>
                    <h1 className="text-xs font-normal text-gray-400">
                      Please evaluate the candidate on the following on a scale
                      of 1 to 5 (1 Lowest, 5 Highest)
                    </h1>
                  </div>
                  <table className="table-fixed w-full mt-2">
                    <thead className="text-sm">
                      <tr>
                        <th className="w-1/2">
                          <p className="p-2 flex justify-start text-xs">
                            Required Technical Skills
                          </p>
                        </th>
                        <th className="w-1/2">
                          <p className="p-2 flex justify-start text-xs">
                            Rating
                          </p>
                        </th>
                        <th className="w-1/2">
                          <p className="p-2 flex justify-start text-xs">
                            Comments
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-gray-400 ">
                      {scheduleDetail.feedback[0].fp_skill.map((x: any) => {
                        return (
                          <tr className="bg-gray-50 border-b-2 border-white ">
                            <td>
                              <p className="p-2 text-xs text-gray-500">
                                {x.skill.name}
                              </p>
                            </td>
                            <td>
                              <p className="p-2 disabled-rating">
                                <StarRatingComp
                                  stars={x.skill_rating}
                                  callBack={(rate: any) => {
                                    console.log(rate);
                                  }}
                                />
                              </p>
                            </td>
                            <td>
                              <p className="p-2 text-xs text-gray-500">
                                {x.experience}
                              </p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <table className="table-fixed w-full mt-2">
                    <thead className="text-sm">
                      <tr>
                        <th className="w-1/2">
                          <p className="p-2 flex justify-start text-xs">
                            Preferred Technical Skills
                          </p>
                        </th>
                        <th className="w-1/2">
                          <p className="p-2 flex justify-start text-xs">
                            Rating
                          </p>
                        </th>
                        <th className="w-1/2">
                          <p className="p-2 flex justify-start text-xs">
                            Comments
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-gray-400 ">
                      {scheduleDetail.feedback[0].fs_skill.map((x: any) => {
                        return (
                          <tr className="bg-gray-50 border-b-2 border-white ">
                            <td>
                              <p className="p-2 text-xs text-gray-500">
                                {x.skill.name}
                              </p>
                            </td>
                            <td>
                              <p className="p-2 disabled-rating">
                                <StarRatingComp
                                  stars={x.skill_rating}
                                  callBack={(rate: any) => {
                                    console.log(rate);
                                  }}
                                />
                              </p>
                            </td>
                            <td>
                              <p className="p-2 text-xs text-gray-500">
                                {x.experience}
                              </p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {scheduleDetail.feedback[0].fm_skill.length > 0 && (
                    <table className="table-fixed w-full mt-2">
                      <thead className="text-sm">
                        <tr>
                          <th className="w-1/2">
                            <p className="p-2 flex justify-start text-xs">
                              Mandatory Skills
                            </p>
                          </th>
                          <th className="w-1/2">
                            <p className="p-2 flex justify-start text-xs">
                              Rating
                            </p>
                          </th>
                          <th className="w-1/2">
                            <p className="p-2 flex justify-start text-xs">
                              Comments
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-gray-400 ">
                        {scheduleDetail.feedback[0].fm_skill.map((x: any) => {
                          return (
                            <tr className="bg-gray-50 border-b-2 border-white ">
                              <td>
                                <p className="p-2 text-xs text-gray-500">
                                  {x.skill.name}
                                </p>
                              </td>
                              <td>
                                <p className="p-2 disabled-rating">
                                  <StarRatingComp
                                    stars={x.skill_rating}
                                    callBack={(rate: any) => {
                                      console.log(rate);
                                    }}
                                  />
                                </p>
                              </td>
                              <td>
                                <p className="p-2 text-xs text-gray-500">
                                  {x.remarks}
                                </p>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
                <div
                  className={
                    scheduleDetail.feedback[0].candidate_no_show ||
                    scheduleDetail.feedback[0].proxy
                      ? "disabled-div flex flex-col items-start gap-2 my-3 w-full"
                      : "flex flex-col items-start gap-2 my-3 w-full"
                  }
                >
                  <div className="pt-2">
                    <h1 className="text-sm font-semibold text-gray-800 mb-2">
                      Level of Experience
                    </h1>
                    <table className="table-fixed w-full mt-2">
                      <thead className="text-sm">
                        <tr>
                          <th className="w-1/2">
                            <p className="p-2 flex justify-start text-xs">
                              Category
                            </p>
                          </th>
                          <th className="w-1/2">
                            <p className="p-2 flex justify-start text-xs">
                              Comments
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-gray-400 ">
                        <tr className="bg-gray-50 border-b-2 border-white ">
                          <td>
                            <p className="p-2 text-xs text-gray-500">Junior</p>
                          </td>

                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              {scheduleDetail.feedback[0].junior}
                            </p>
                          </td>
                        </tr>
                        <tr className="bg-gray-50 border-b-2 border-white ">
                          <td>
                            <p className="p-2 text-xs text-gray-500">Mid</p>
                          </td>

                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              {scheduleDetail.feedback[0].mid}
                            </p>
                          </td>
                        </tr>
                        <tr className="bg-gray-50 border-b-2 border-white ">
                          <td>
                            <p className="p-2 text-xs text-gray-500">Senior</p>
                          </td>

                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              {scheduleDetail.feedback[0].senior}
                            </p>
                          </td>
                        </tr>
                        <tr className="bg-gray-50 border-b-2 border-white ">
                          <td>
                            <p className="p-2 text-xs text-gray-500">Lead</p>
                          </td>

                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              {scheduleDetail.feedback[0].lead}
                            </p>
                          </td>
                        </tr>

                        <tr className="bg-gray-50 border-b-2 border-white ">
                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              Architect
                            </p>
                          </td>

                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              {scheduleDetail.feedback[0].architect}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className={
                    scheduleDetail.feedback[0].candidate_no_show ||
                    scheduleDetail.feedback[0].proxy
                      ? "disabled-div flex flex-col items-start gap-2 my-3 w-full"
                      : "flex flex-col items-start gap-2 my-3 w-full"
                  }
                >
                  <p className="font-semibold text-xs underline text-gray-900">
                    Communication level of expertise (Defined standard, level of
                    oral articulation)
                  </p>
                  <div className="flex flex-col items-start justify-start ml-6 text-xs font-semibold text-gray-600 gap-2">
                    <div className="flex items-center">
                      <input
                        type="radio"
                        value={"1"}
                        name="com_level"
                        // disabled
                        checked={
                          scheduleDetail.feedback[0].communication_skill === 1
                        }
                      ></input>
                      <label className="ml-2 text-xs text-gray-900">
                        1.Speaks with limited fluency, irregular grammar and
                        carries a distinct accent
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="radio"
                        value={"2"}
                        name="com_level"
                        // disabled
                        checked={
                          scheduleDetail.feedback[0].communication_skill === 2
                        }
                      ></input>
                      <label className="ml-2 text-xs text-gray-900">
                        2.Speaks with comfortable fluency, agreeable grammar,
                        has noticeable accent
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="radio"
                        value={"3"}
                        name="com_level"
                        // disabled
                        checked={
                          scheduleDetail.feedback[0].communication_skill === 3
                        }
                      ></input>
                      <label className="ml-2 text-xs text-gray-900">
                        3.Speaks with acceptable finesse/ accurate grammar and
                        has no accent
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        type="radio"
                        value={"4"}
                        name="com_level"
                        // disabled
                        checked={
                          scheduleDetail.feedback[0].communication_skill === 4
                        }
                      ></input>
                      <label className="ml-2 text-xs text-gray-900">
                        4.Speaks with polish / finesse. Demonstrates confidence
                        in presenting the credentials with ease
                      </label>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    scheduleDetail.feedback[0].candidate_no_show ||
                    scheduleDetail.feedback[0].proxy
                      ? "disabled-div flex flex-col items-start gap-2 my-3 w-full"
                      : "flex flex-col items-start gap-2 my-3 w-full"
                  }
                >
                  <div className="pt-2">
                    <h1 className="text-sm font-semibold text-gray-800 mb-2">
                      Observations
                    </h1>
                    <table className="table-fixed w-full mt-2">
                      <thead className="text-sm">
                        <tr>
                          <th className="w-1/2">
                            <p className="p-2 flex justify-start text-xs">
                              Communication Level
                            </p>
                          </th>
                          <th className="w-1/2">
                            <p className="p-2 flex justify-start text-xs">
                              (Ref one of the four listed above)
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-gray-400 ">
                        <tr className="bg-gray-50 border-b-2 border-white ">
                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              Technical Observation
                            </p>
                          </td>

                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              {scheduleDetail.feedback[0].observation}
                            </p>
                          </td>
                        </tr>
                        <tr className="bg-gray-50 border-b-2 border-white ">
                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              Analytical and problem-solving Skills
                            </p>
                          </td>

                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              {scheduleDetail.feedback[0].problem_solving_cmd}
                            </p>
                          </td>
                        </tr>
                        <tr className="bg-gray-50 border-b-2 border-white ">
                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              Attitude / Aptitude demonstrated
                            </p>
                          </td>

                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              {
                                scheduleDetail.feedback[0]
                                  .attitude_confidence_cmd
                              }
                            </p>
                          </td>
                        </tr>
                        <tr className="bg-gray-50 border-b-2 border-white ">
                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              Clarity of the answers provided
                            </p>
                          </td>

                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              {
                                scheduleDetail.feedback[0]
                                  .inter_personal_skill_cmd
                              }
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className={
                    scheduleDetail.feedback[0].candidate_no_show ||
                    scheduleDetail.feedback[0].proxy
                      ? "disabled-div flex flex-col items-start gap-2 my-3 w-full"
                      : "flex flex-col items-start gap-2 my-3 w-full"
                  }
                >
                  <div className="pt-2">
                    <h1 className="text-sm font-semibold text-gray-800 mb-2">
                      Final Recommendation
                    </h1>
                    <table className="table-fixed w-full mt-2">
                      <tbody className="text-gray-400 ">
                        <tr className="bg-gray-50 border-b-2 border-white ">
                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              Must Hire/Hire/Hold/Do not Hire
                            </p>
                          </td>

                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              {scheduleDetail.feedback[0].recommended_status}
                            </p>
                          </td>
                        </tr>
                        <tr className="bg-gray-50 border-b-2 border-white ">
                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              Junior/ Mid/ Senior/ Lead/ Architect
                            </p>
                          </td>

                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              {scheduleDetail.feedback[0].professionalism_cmd}
                            </p>
                          </td>
                        </tr>
                        <tr className="bg-gray-50 border-b-2 border-white ">
                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              Other Comments, if any:
                            </p>
                          </td>

                          <td>
                            <p className="p-2 text-xs text-gray-500">
                              {scheduleDetail.feedback[0].comments}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className={
                    scheduleDetail.feedback[0].candidate_no_show ||
                    scheduleDetail.feedback[0].proxy
                      ? "disabled-div flex flex-col items-start gap-2 my-3 w-full"
                      : "flex flex-col items-start gap-2 my-3 w-full"
                  }
                >
                  <div className="pt-2">
                    <h1 className="text-sm font-semibold text-gray-800 mb-2">
                      Recommended?
                    </h1>
                    <div className="flex justify-left gap-2">
                      <div className="flex justify-start item-center gap-1">
                        <input
                          type="radio"
                          name="Recommended"
                          value="true"
                          // disabled
                          checked={
                            scheduleDetail.feedback[0]
                              .eligible_for_this_position
                          }
                        />
                        <h1 className="text-black text-xs font-bold">Yes</h1>
                      </div>
                      <div className="flex justify-start item-center gap-1">
                        <input
                          type="radio"
                          name="Recommended"
                          value="false"
                          // disabled
                          checked={
                            !scheduleDetail.feedback[0]
                              .eligible_for_this_position
                          }
                        />
                        <h1 className="text-black text-xs font-bold">No</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="py-5">
                {base64Img ? (
                  <>
                    <h1 className="text-sm font-semibold text-gray-800 mb-2">
                      Candidate Profile Picture
                    </h1>
                    <div className="h-32 w-32 mr-1 bg-gray-100 rounded-sm overflow-hidden">
                      <img
                        src={base64Img!}
                        alt="user_profile_photo"
                        className="h-full w-full object-cover cursor-pointer"
                        onClick={() => setOpenImageFullView(true)}
                      ></img>
                    </div>
                  </>
                ) : (
                  <>
                    <h1 className="text-sm font-semibold text-gray-800 mb-2">
                      Candidate Profile Picture
                    </h1>
                    <div className="h-32 w-32 bg-primary rounded-full overflow-hidden ">
                      <div className="flex justify-center items-center h-32">
                        <BsPerson color="white" size={70} />
                      </div>
                    </div>
                  </>
                )}
              </div>
              {scheduleDetail.code && scheduleDetail.code.length > 0 && (
                <div className="py-5">
                  <div className="pt-7 border-b border-blue-300 avoid">
                    <h1 className="text-sm font-semibold text-gray-800 mb-2">
                      Programming Code
                    </h1>
                  </div>

                  {scheduleDetail.code.map((x: any) => (
                    <div className="avoid">
                      <h1 className="text-sm font-semibold text-gray-900 pt-2">
                        {x.title}
                      </h1>
                      <pre className="text-sm font-normal text-gray-400 pb-2">
                        {x.code}
                      </pre>
                    </div>
                  ))}
                </div>
              )}
              {scheduleDetail.video_url && (
                <div className="py-5">
                  <div className="pt-7 border-b border-blue-300 avoid">
                    <h1 className="text-sm font-semibold text-gray-800 mb-2">
                      Interview Video
                    </h1>
                  </div>
                  <h1 className="text-md font-bold text-black py-10">
                    {S.getFileExtension(scheduleDetail.video_url) !== "mp4" ? (
                      <ReactHlsPlayer
                        src={scheduleDetail.video_url}
                        autoPlay={false}
                        controls={true}
                        width="750"
                        height="500"
                      />
                    ) : (
                      <video
                        src={scheduleDetail.video_url}
                        autoPlay={false}
                        controls={true}
                        width="750"
                        height="500"
                      />
                    )}
                  </h1>
                </div>
              )}
              {/* <div className="py-5">
                <h1
                  onClick={() => {
                    openMeetingChat();
                  }}
                  className="text-sm font-semibold text-primary underline mb-2 cursor-pointer"
                  id="viewchat"
                >
                  View Chat
                </h1>
              </div> */}
              <div className="my-5"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewTechFeedbackNamIT;
