import axios from "./axios";

const orgApi = {
  // ****** Dashboard ******
  dashboard: async () => {
    return await axios.post(`/signup`);
  },
  dashSchedule: async (id: string) => {
    return await axios.get(`/org/dashboard/${id}/schedule`);
  },
  dashLive: async (id: string) => {
    return await axios.get(`/org/${id}/live_interview`);
  },
  getSubs: async (id: string) => {
    return await axios.get(`/org/${id}/subscription`);
  },

  // ****** JD ******
  skills: async (data: any) => {
    return await axios.post(`/org/skills`, data);
  },
  addPost: async (orgId: any, data: any) => {
    return await axios.post(`/org/` + orgId + `/post`, data);
  },
  editPost: async (postId: any, data: any) => {
    return await axios.put(`/org/` + postId + `/post`, data);
  },
  uploadJd: async (postId: any, data: any) => {
    return await axios.post(`/org/` + postId + `/upload_jd`, data);
  },
  jdTemplate: async (orgId: any, data: any) => {
    return await axios.post(`/org/` + orgId + `/template`, data);
  },
  templateDetail: async (templateId: any) => {
    return await axios.get(`/org/` + templateId + `/template`);
  },
  getBusinessUnit: async (orgId: any) => {
    return await axios.get(`/org/` + orgId + `/business_unit`);
  },
  addBusinessUnit: async (orgId: any, data: any) => {
    return await axios.post(`/org/` + orgId + `/business_unit`, data);
  },
  addAdditionalSkill: async (data: any) => {
    return await axios.post(`org/skill_add`, data);
  },
  fileReader: async (data: any) => {
    return await axios.post(`/org/file_reader`, data);
  },
  getJdList: async (orgId: any, data: any) => {
    return await axios.post(`/org/` + orgId + `/post_list`, data);
  },
  getSingleJdDetail: async (postId: any) => {
    return await axios.get(`/org/post/` + postId);
  },
  addRound: async (data: any) => {
    return await axios.post(`/org/post/round`, data);
  },
  editRound: async (data: any) => {
    return await axios.put(`/org/post/round`, data);
  },
  getRound: async (post_id: any) => {
    return await axios.get(`/org/round/${post_id}/detail`);
  },
  fetchSelfEveluation: async (scheduleID: any) => {
    return await axios.get(`/interviewer/${scheduleID}/self_evaluation`);
  },
  submitSelfEveluation: async (scheduleID: any, data: any) => {
    return await axios.post(`/interviewer/${scheduleID}/self_evaluation`,data);
  },
  candidateSelfEveluation: async (candidateID: any) => {
    return await axios.get(`/org/${candidateID}/candidate_self_evaluation`);
  },
  changeScope: async (scheduleId: any, data: any) => {
    return await axios.post(`/org/${scheduleId}/change_scope`, data);
  },
  addManualFeedback: async (scheduleId: any, data: any) => {
    return await axios.put(`/org/${scheduleId}/change_scope`, data);
  },
  fetchSingledateSchedule: async (orgId: string, data: any) => {
    return await axios.post(`/org/${orgId}/live_interview`, data);
  },
  getUrl: async (data: any) => {
    return await axios.post(`/interviewer/url_gen`, data);
  },
  getMandatorySkills: async () => {
    return await axios.post(`/org/1/mandatory_skill`);
  },

  // ****** Schedules ******
  uploadCandidate: async (data: any, postId: any) => {
    return await axios.post(`/org/` + postId + `/candidate_resume`, data);
  },
  getCandidateList: async (data: any, rId: any) => {
    return await axios.post(`/org/round/${rId}/detail`, data);
  },
  getScheduleDetail: async (scheduleId: any, interviewerId: any) => {
    return await axios.get(
      `/interviewer/` + scheduleId + `/` + interviewerId + `/get_info`
    );
  },
  setAvailability: async (scheduleId: any, data: any) => {
    return await axios.post(`/org/` + scheduleId + `/set_availability`, data);
  },
  addInvitees: async (scheduleId: any, data: any) => {
    return await axios.put(`/org/` + scheduleId + `/set_availability`, data);
  },
  getScheduleData: async (scheduleId: any) => {
    return await axios.get(`/interviewer/` + scheduleId + `/meeting`);
  },
  candidateAvailability: async (data: any, scId: any) => {
    return await axios.post(`/interviewer/${scId}/set_availability`, data);
  },
  fetchRoundDetails: async (scId: any) => {
    return await axios.get(`/interviewer/${scId}/set_availability`);
  },
  scheduleResponse: async (intId: any, data: any) => {
    return await axios.post(`/interviewer/` + intId + `/res_schedule`, data);
  },
  addInterviewerSchedule: async (scheduleId: any, data: any) => {
    return await axios.post(
      `/org/` + scheduleId + `/add_interviewer_schedule`,
      data
    );
  },
  getCancelReason: async () => {
    return await axios.get(`/interviewer/cancel_reason`);
  },
  updateCandidate: async (id: any, pid: any, data: any) => {
    return await axios.post(`/org/${id}/${pid}/candidate_profile`, data);
  },
  selectCandidate: async (id: string, data: any) => {
    return await axios.post(`/org/${id}/select`, data);
  },
  ManualScheduleSetAvailability: async (id:string, data: any) => {
    return await axios.post(`/org/${id}/manual_availability`, data);
  },
  reSchedule: async (id: string, data: any) => {
    return await axios.post(`/org/${id}/reschedule`, data);
  },
  cancelSchedule: async (id: string, data: any) => {
    return await axios.post(`/org/${id}/cancel`, data);
  },
  scheduleHistory: async (id: string, cId: string) => {
    return await axios.get(`/org/${id}/${cId}/schedule`);
  },
  fetchInterviewSchedule: async (orgId: string, data: any) => {
    return await axios.post(`/org/dashboard/${orgId}/schedule`, data);
  },
  extractResumes: async (data: any, postId: any) => {
    return await axios.put(`/org/` + postId + `/candidate_resume`, data);
  },
  deleteCandidate: async (id: string) => {
    return await axios.delete(`/org/${id}/delete`);
  },
  rateInterview: async (scheduleId: any, data: any) => {
    return await axios.post(`/org/${scheduleId}/interviewer_rating`, data);
  },
  moveToNext: async (sId: string, data: any) => {
    return await axios.put(`/org/${sId}/select`, data);
  },

  // ****** Iterviewers ******
  getInterviewer: async (postId: any, data: any) => {
    return await axios.post(`/org/` + postId + `/relevant_interviewer`, data);
  },
  getFavInterviewer: async (postId: any, data: any) => {
    return await axios.put(`/org/` + postId + `/fav_interviewer`, data);
  },
  addFavInterviewer: async (postId: any, data: any) => {
    return await axios.post(`/org/` + postId + `/fav_interviewer`, data);
  },
  removeFavInterviewer: async (postId: any, intId: any) => {
    return await axios.delete(
      `/org/` + postId + `/` + intId + `/fav_interviewer`
    );
  },
  getInvitedInterviewers: async (scheduleID: any) => {
    return await axios.get(`/org/` + scheduleID + `/select_interviewer`);
  },

  // ****** Org Profile ******
  getOrgDetails: async (orgId: any) => {
    return await axios.get(`/org/${orgId}/details`);
  },
  roleTemplate: async () => {
    return await axios.get(`/org/1/get_role_template`);
  },
  roleTemplateSkill: async (role_tem_id: any) => {
    return await axios.get(`/org/${role_tem_id}/role_template_skill`);
  },

  // ****** Report ******
  scheduleReport: async (id: string, data: any) => {
    return await axios.post(`/org/${id}/schedule_report`, data);
  },
  exportScheduleReport: async (id: string, data: any) => {
    return await axios.post(`/org/${id}/export_schedule_report`, data);
  },
  interviewReport: async (id: string, data: any) => {
    return await axios.post(`/org/${id}/interview_report`, data);
  },
  exportInterviewReport: async (id: string, data: any) => {
    return await axios.post(`/org/${id}/export_interview_report`, data);
  },
  jdReport: async (id: string, data: any) => {
    return await axios.post(`/org/${id}/con_jd_report`, data);
  },
  taReport: async (id: string, data: any) => {
    return await axios.post(`/org/${id}/con_ta_report`, data);
  },
  jdReportSchedule: async (post_id: string, data: any) => {
    return await axios.post(`/org/${post_id}/con_jd_schedule_report`, data);
  },
  taReportSchedule: async (org_id: string, data: any) => {
    return await axios.post(`/org/${org_id}/con_ta_schedule_report`, data);
  },
  adminReport: async (data: any) => {
    return await axios.post(`/org/admin_report`, data);
  },

  // ****** Interviewer Management ******
  inviteInterviewers: async (orgId: any, data: any) => {
    return await axios.post(`/org/` + orgId + `/panel`, data);
  },
  fetchOrgInterviewers: async (orgId: any, data: any) => {
    return await axios.put(`/org/` + orgId + `/panel`, data);
  },
  listInterviewerGroup: async (orgId: any, data: any) => {
    return await axios.post(`/org/` + orgId + `/interviewer_group`, data);
  },
  addInterviewerGroup: async (data: any) => {
    return await axios.post(`/org/interviewer_group`, data);
  },
  updateInterviewerGroup: async (grpId: any, data: any) => {
    return await axios.put(`/org/` + grpId + `/interviewer_group`, data);
  },
  addGroupInterviewer: async (grpId: any, data: any) => {
    return await axios.post(`/org/group/` + grpId + `/interviewer`, data);
  },
  removeGroupInterviewer: async (grpId: any, grpIntId: any) => {
    return await axios.delete(`/org/group/` + grpId + `/` + grpIntId +`/interviewer`);
  },
  listGroupInterviewer: async (grpId: any, data: any) => {
    return await axios.post(`/org/` + grpId + `/group_interviewer`, data);
  },
  listRoundGroup: async (roundId: any, data: any) => {
    return await axios.post(`/org/` + roundId + `/round_group`, data);
  },
  addRoundGroup: async (roundId: any, data: any) => {
    return await axios.post(`/org/` + roundId + `/add_round_group`, data);
  },

  // ****** User Management ******
  addRec: async (orgId: any, data: any) => {
    return await axios.post(`/org/${orgId}/add_team`, data);
  },
  getRec: async (orgId: any) => {
    return await axios.get(`/org/${orgId}/get_team`);
  },
};

export default orgApi;