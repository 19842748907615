import { format } from "date-fns";
import { useFormik } from "formik";
import { CountryCode, parsePhoneNumberFromString } from "libphonenumber-js";
import moment from "moment";
import "moment-timezone";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import ReactHlsPlayer from "react-hls-player";
import { AiOutlineSync } from "react-icons/ai";
import {
  BsCalendar,
  BsCalendarFill,
  BsClock,
  BsClockFill,
  BsEnvelope,
  BsFillTelephoneFill,
  BsMenuButtonFill,
  BsPencil,
  BsPersonFill,
  BsThreeDotsVertical,
  BsTrash3,
  BsUpload,
} from "react-icons/bs";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StarRatings from "react-star-ratings";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import { timezone } from "../../../Services/Data";
import S from "../../../Services/Services";
import { BaseUrl } from "../../../api/basr_url";
import orgApi from "../../../api/org_api";
import { CloseModal } from "../../../component/Layout/CloseModal";
import SwitchComp from "../../../component/Switch";
import { Modal } from "../../../component/common";
import { Style } from "../../../utils/Style";
import { AvailabilityModal } from "./components";
const nodata = require("../../../assets/images/nodata.png");

type CandidateListProps = { setLoading: Function };

export const CandidateList: React.FC<CandidateListProps> = ({
  setLoading,
}: CandidateListProps) => {
  const { post_id, round_id, level } = useParams();
  const { state } = useLocation();
  const [candidateList, setCandidateList] = useState([]);
  const [tempCandidateList, setTempCandidateList] = useState([]);
  const [jdDetail, setJdDetail] = useState();
  const [status, setStatus] = useState("");
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [search, setSearch] = useState("");
  const [schedule, setSchedule] = useState({} as any);
  const [availabilityModal, setAvailabilityModal] = useState(false);
  const [invitedIntModal, setInvitedIntModal] = useState(false);
  const [schDetailModal, setSchDetailModal] = useState(false);
  const [uploadResumeModal, setUploadResumeModal] = useState(false);
  const [editCandiModal, setEditCandiModal] = useState(false);
  const [showFeedbackModal, setFeedbackModal] = useState(false);
  const [schModal, setSchModal] = useState(false);
  const [selectedTZ, setSelectedTZ] = useState("Asia/Calcutta");
  const [available_datetime, setAvailableDatetime] = useState<Date | null>(
    null
  );
  const [datetime_error, setDatetimeError] = useState("");
  const [schedule_id, setScheduleId] = useState("");
  const [invited, setInvited] = useState([]);
  const [defaultPage, setDefaultPage] = useState("video");
  const [selectedFile, setSelectedFile] = useState<File[]>([]);
  const [fileError, setfileError] = useState("");
  const [schUpdate, setSchUpdate] = useState("reschedule");
  const [cancelReason, setCancelReason] = useState([]);
  const [history, setHistory] = useState([]);
  const [reason, setReason] = useState("");
  const [reasonCmd, setReasonCmd] = useState("");
  const [showHistory, setShowHistory] = useState(false);
  const [int_rate, setIntRate] = useState(0);
  const [int_comment, setIntComment] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [moveNextModal, setMoveNextModal] = useState(false);
  const [phErrorMsg, setPhErrorMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [defaultCC, setDefaultCC] = useState<CountryCode>("IN");
  const [deleteResumeConfirmation, setDeleteResumeConfirmation] =
    useState(false);
  const [isDisabled, setisDisabled] = useState(false);
  const [otherEmailError, setOtherEmailError] = useState("");
  const [otherEmails, setOtherEmails] = useState([] as any);
  const [addIniteesModel, showAddIniteesModel] = useState(false);
  const [candidateRateModel, showCandidateRateModel] = useState(false);
  const [openIndex, setOpenIndex] = useState(-1);
  const [primary, setPrimary] = useState([] as any);
  const [secondary, setSecondary] = useState([] as any);
  const [resumeType, setResumeType] = useState([] as any);
  const [scopeConfirmationModel, showScopeConfirmationModel] = useState(false);
  const [scheduleScope, setScheduleScope] = useState("");
  const [getFeedbackModal, showGetFeedbackModal] = useState(false);
  const [selectedFeedFile, setSelectedFeedFile] = useState<File>();
  const [manualFeedback, setManualFeedback] = useState({
    video_url: "",
    sit: 0,
    next_type: "auto",
  });
  const userInfo = JSON.parse(localStorage.getItem("user__details") as string);
  const paid = userInfo.paid;
  const orgId = userInfo.id;
  const masterId =
    userInfo.master_id !== null ? userInfo.master_id : userInfo.id;
  const navigate = useNavigate();

  const [isReschedule, setIsReschedule] = useState(false);
  const [cancelScheduleModal, setCancelScheduleModal] = useState(false);
  const [rescheduleData, setRescheduleData] = useState<{
    event_interviewer: string;
    event_org: string;
  }>({
    event_interviewer: "",
    event_org: "",
  });

  const toggleDropdown = (index: number) =>
    setOpenIndex(index === openIndex ? -1 : index);

  const [manualAvailabilityModal, setManualAvailabilityModal] =
    useState<boolean>(false);
  const [manuallyScheduled, setManuallyScheduled] = useState<boolean>(false);

  const handleManualAvailability = async (data: {
    date: string;
    timezone: string;
    invitees: string[];
  }) => {
    if (!schedule_id) {
      toast.error("Schedule ID is required");
      return;
    }
    try {
      const reqData = {
        available_datetime: data.date,
        tz: data.timezone,
        invitees: data.invitees.join(", "),
        address: rescheduleData.event_org || "",
        location: rescheduleData.event_interviewer || "",
      };

      const res = await orgApi.ManualScheduleSetAvailability(
        schedule_id,
        reqData
      );
      toast(res.data.msg);
      setManuallyScheduled(true);
      fetchCandidate();
      console.log(manuallyScheduled);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setIsReschedule(false);
    }
  };

  useEffect(() => {
    fetchCandidate();
  }, [status, search.length > 2, from, to]);
  const fetchCandidate = async () => {
    setLoading(true);
    const reqData = {
      search,
      status,
      from: from ? format(from, "yyyy-MM-dd 00:00:00") : "",
      to: to ? format(to, "yyyy-MM-dd 23:59:59") : "",
      sit: "",
      greater: "",
      level,
      org_id: orgId,
    };
    const res = await orgApi.getCandidateList(reqData, round_id);
    if (res.data.success) {
      setCandidateList(res.data.candidate);
      setTempCandidateList(res.data.temp_candidate);
      setJdDetail(res.data.post);
    } else {
      setCandidateList([]);
      setTempCandidateList([]);
      setJdDetail(undefined);
    }
    setLoading(false);
  };
  const setAvailability = async () => {
    if (available_datetime) {
      setDatetimeError("");
    } else {
      setDatetimeError("Please select the availability date and time");
      return;
    }
    var reqData = {
      available_datetime: format(available_datetime, "yyyy-MM-dd HH:mm:ss"),
      tz: selectedTZ,
    } as any;
    if (jdDetail && jdDetail["round"][0]["fav"] !== true) {
      var roles = [];
      jdDetail["round"][0]["external"] === true && roles.push("external");
      jdDetail["round"][0]["house"] === true && roles.push("in-house");
      // jdDetail["round"][0]["market"] === true && roles.push("interviewer");
      reqData["role"] = roles;
    }
    if (otherEmails.length !== 0) {
      var oe = otherEmails.join(",");
      reqData["invitees"] = oe;
    } else {
      const oes = document.getElementById(
        "other_email"
      ) as HTMLInputElement | null;
      if (oes) {
        reqData["invitees"] = oes.value;
      }
    }
    setOtherEmails([]);
    setisDisabled(true);
    setLoading(true);
    const res = await orgApi.setAvailability(schedule_id, reqData);
    fetchCandidate();
    toast(res.data.msg);
    setLoading(false);
    setisDisabled(false);
    setScheduleId("");
    setAvailableDatetime(null);
    setAvailabilityModal(false);
  };
  const openSetAvailability = (scheduleId: any) => {
    setScheduleId(scheduleId);
    setAvailableDatetime(null);
    setAvailabilityModal(true);
  };
  const handleFromDateChange = (date: any) => setFrom(date);
  const handleToDateChange = (date: any) => setTo(date);
  const clearFilter = () => {
    setStatus("");
    setFrom(null);
    setTo(null);
  };
  const handleChangeTZ = (event: any) => setSelectedTZ(event.target.value);

  const handleDateChange = (date: any) => {
    setDatetimeError("");
    setAvailableDatetime(date);
  };
  const viewInvitedInterviewers = async (scheduleId: any) => {
    const res = await orgApi.getInvitedInterviewers(scheduleId);
    if (res.data.success) {
      setInvited(res.data.selected_interviewer);
      setInvitedIntModal(true);
    } else {
      toast(res.data.msg);
    }
  };
  const scheduleAvailability = async (scheduleId: any) => {
    var reqData = {} as any;
    if (jdDetail && jdDetail["round"][0]["fav"] !== true) {
      var roles = [];
      jdDetail["round"][0]["external"] === true && roles.push("external");
      jdDetail["round"][0]["house"] === true && roles.push("in-house");
      jdDetail["round"][0]["market"] === true && roles.push("interviewer");
      reqData["role"] = roles;
    }
    const res = await orgApi.addInterviewerSchedule(scheduleId, reqData);
    res.data.success ? toast(res.data.msg) : toast(res.data.msg);
    fetchCandidate();
  };
  const selectCandidate = async (sId: string, action: string) => {
    const reqData = { action };
    const res = await orgApi.selectCandidate(sId, reqData);
    res.data.success ? toast(res.data.msg) : toast(res.data.msg);
    fetchCandidate();
  };
  const getCancelReason = async () => {
    const res = await orgApi.getCancelReason();
    setCancelReason(res.data.reason);
  };
  const scheduleHistory = async (cId: string) => {
    const res = await orgApi.scheduleHistory(post_id!, cId);
    setHistory(res.data.schedule);
    setShowHistory(true);
  };
  const cancelSchedule = async () => {
    if (reason === "") {
      toast("Select Reason");
      return;
    }
    setLoading(true);
    setisDisabled(true);
    const reqData = { cancel_reason_id: reason, cancel_reason: reasonCmd };
    const res = await orgApi.cancelSchedule(schedule_id, reqData);
    res.data.success ? toast(res.data.msg) : toast(res.data.msg);
    setLoading(false);
    setisDisabled(false);
    if (res.data.success) setSchModal(false);
    fetchCandidate();
  };
  const reSchedule = async () => {
    if (available_datetime) {
      setDatetimeError("");
    } else {
      setDatetimeError("Please select the availability date and time");
      return;
    }
    if (reason === "") {
      toast("Select Reason");
      return;
    }
    const reqData = {
      cancel_reason_id: reason,
      cancel_reason: reasonCmd,
      available_datetime: format(available_datetime, "yyyy-MM-dd HH:mm:ss"),
      tz: selectedTZ,
    };
    setLoading(true);
    setisDisabled(true);
    const res = await orgApi.reSchedule(schedule_id, reqData);
    toast(res.data.msg);
    setLoading(false);
    setisDisabled(false);
    fetchCandidate();
    setScheduleId("");
    setAvailableDatetime(null);
    setSchModal(false);
  };
  const getTz = (tz: any) => {
    var timezonetype = "";
    for (let i = 0; i < timezone.length; i++) {
      timezone[i].fullName === tz && (timezonetype = timezone[i].name);
    }
    return timezonetype;
  };
  const openScheduleDetail = async (sx: any) => {
    setSchedule(sx);
    setSchDetailModal(true);
  };
  const openFeedbackDetail = (value: any) => {
    setSchedule(value);
    setFeedbackModal(true);
  };
  const openUploadResume = () => {
    setSelectedFile([]);
    setUploadResumeModal(true);
  };
  const openEditcandidate = (value: any) => {
    formik.resetForm();
    formik.values.name = value.first_name;
    formik.values.lastName = value.last_name;
    formik.values.email = value.email;
    formik.values.aadhar =
      value.aadhar === 0 || value.aadhar === null || value.aadhar === undefined
        ? ""
        : value.aadhar;
    formik.values.ssn =
      value.ssn === 0 || value.ssn === null || value.ssn === undefined
        ? ""
        : value.ssn;

    if (value.mobile) {
      setPhone(`+${value.mobile_country_code}${value.mobile}`);
      setCountryCode(value.mobile_country_code);
      const countryShortName: CountryCode = getCountryShortNameByCallingCode(
        `${value.mobile_country_code}${value.mobile}`
      );
      setDefaultCC(countryShortName);
    } else {
      setPhone("");
      setCountryCode("91");
      setDefaultCC("IN");
    }

    formik.values.id = value.id;
    setEditCandiModal(true);
  };
  const handleImageChange = async (event: any) => {
    const ds: any = await S.handleUploadResume(event.target.files);
    setfileError(ds["error"]);
    setSelectedFile(ds["resumefiles"]);
    setResumeType(ds["resumeType"]);
  };
  const handleFeedbackChange = async (event: any) => {
    const file = event.target.files[0];
    setfileError("");
    if (file) {
      if (
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type === "application/pdf" ||
        file.type.startsWith("image/")
      ) {
        if (file.size <= 3 * 1024 * 1024) {
          setSelectedFeedFile(file);
          setfileError("");
        } else {
          setfileError("Please upload the file with size <3 MB");
        }
      } else {
        setfileError("Please upload only pdf or docx or image format");
      }
    }
  };
  const getSubscription = async (resLen: any, roundTag: any) => {
    setLoading(true);
    let orgId = userInfo.master_id !== null ? userInfo.master_id : userInfo.id;
    const res = await orgApi.getSubs(orgId);
    if (res.data.success) {
      setLoading(false);
      let subscription = res.data.org;
      if (roundTag === "external" && subscription.e_available < resLen) {
        toast(
          `You can upload a maximum of ${subscription.e_available} resumes.`
        );
        return false;
      } else if (roundTag === "internal" && subscription.i_available < resLen) {
        toast(
          `You can upload a maximum of ${subscription.i_available} resumes.`
        );
        return false;
      } else if (roundTag === "combination") {
        let min =
          subscription.i_available >= subscription.e_available
            ? subscription.e_available
            : subscription.i_available;
        if (min < resLen) {
          toast(`You can upload a maximum of ${min} resumes.`);
          return false;
        }
      }
    } else {
      setLoading(false);
      return true;
    }
    return true;
  };

  const getRoundTag = async (d: any) => {
    let roundTag = "";
    if (
      d["fav"] ||
      (d["house"] && d["market"] && !d["external"]) ||
      (!d["house"] && d["market"] && d["external"]) ||
      (d["house"] && d["market"] && d["external"])
    ) {
      roundTag = "combination";
    } else if (!d["house"] && d["market"] && !d["external"]) {
      roundTag = "external";
    } else if (
      (d["house"] && !d["market"] && !d["external"]) ||
      (!d["house"] && !d["market"] && d["external"]) ||
      (d["house"] && !d["market"] && d["external"])
    ) {
      roundTag = "internal";
    }
    return roundTag;
  };
  const uploadResume = async () => {
    let roundTag = "";
    if (jdDetail) {
      let d = jdDetail["round"][0];
      roundTag = await getRoundTag(d);
    }
    let scope = [] as any;
    resumeType.forEach((r_type: any) =>
      scope.push({ type: r_type ? "sit" : "others" })
    );
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const formData = new FormData();
    formData.append("tz", timezone);
    formData.append("org_id", orgId);
    formData.append("master_id", masterId);
    formData.append("tag", roundTag);
    formData.append("scope", JSON.stringify(scope));
    let mv: Boolean | undefined = await getSubscription(
      selectedFile.length,
      roundTag
    );
    if (!mv) {
      return;
    }
    for (let k = 0; k < selectedFile.length; k++) {
      formData.append("resume", selectedFile[k]);
    }
    setLoading(true);
    setisDisabled(true);
    const res = await orgApi.uploadCandidate(formData, post_id);
    if (res.data.success === true) {
      toast(res.data.msg);
      setUploadResumeModal(false);
      fetchCandidate();
    } else {
      toast(res.data.msg);
    }
    setisDisabled(false);
    setLoading(false);
  };
  const removeResume = async (index: number) => {
    setSelectedFile((prevState) => prevState.filter((_, ind) => ind !== index));
    setResumeType((prevState: any) =>
      prevState.filter((_: any, ind: number) => ind !== index)
    );
  };
  const deleteCandidate = async (id: string) => {
    const res = await orgApi.deleteCandidate(id);
    setisDisabled(true);
    res.data.success
      ? toast("Resume deleted successfully")
      : toast(res.data.msg);
    setDeleteResumeConfirmation(false);
    setisDisabled(false);
    fetchCandidate();
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      lastName: "",
      email: "",
      id: "",
      aadhar: "",
      ssn: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("First name is required"),
      email: Yup.string().required("Email is required"),
      aadhar: Yup.string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(4, "Aadhar number must have 4 digits")
        .max(4, "Aadhar number must have 4 digits"),
      ssn: Yup.string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(4, "SSN number must have 4 digits")
        .max(4, "SSN number must have 4 digits"),
    }),
    validateOnBlur: true,
    onSubmit: async (values) => {
      var ph;
      if (phone !== undefined && countryCode) {
        ph = phone.replace("+" + countryCode, "");
        if (ph !== "") {
        } else {
          setPhErrorMessage("Please enter the valid Phone number");
          return;
        }
      } else {
        setPhErrorMessage("Please enter the valid Phone number");
        return;
      }
      const req = {
        first_name: values.name,
        last_name: values.lastName,
        email: values.email,
        mobile: ph!,
        mobile_country_code: countryCode,
        org_id: orgId,
        aadhar: values.aadhar !== "" ? Number(values.aadhar) : 0,
        ssn: values.ssn !== "" ? Number(values.ssn) : 0,
      };
      setisDisabled(true);
      jdDetail &&
        (await orgApi.updateCandidate(values.id, jdDetail["id"], req));
      setEditCandiModal(false);
      setisDisabled(false);
      fetchCandidate();
    },
  });

  const submitRating = async (id: any) => {
    if (int_comment === "") {
      toast("Please enter the comments");
      return;
    }
    const data = { comments: int_comment, rating: int_rate };
    const res = await orgApi.rateInterview(id, data);
    toast(res.data.msg);
    setFeedbackModal(false);
    fetchCandidate();
  };
  const moveToNextSubmit = async (sId: string, action: string) => {
    let roundTag = "";
    if (jdDetail) {
      const d = jdDetail["round"][0];
      roundTag = await getRoundTag(d);
    }
    if (selectedType === "NR") {
      const mv: Boolean | undefined = await getSubscription(1, roundTag);
      if (!mv) {
        return;
      }
    }
    const reqData = {
      action,
      master_id: masterId,
      tag: roundTag,
      scope: manualFeedback.next_type === "manual" ? false : true,
    };
    setisDisabled(true);
    const res = await orgApi.moveToNext(sId, reqData);
    res.data.success ? toast(res.data.msg) : toast(res.data.msg);
    setisDisabled(false);
    setMoveNextModal(false);
    setOpenIndex(-1);
    fetchCandidate();
  };

  function handlechange(e: any) {
    setPhErrorMessage("");
    setPhone(e);
    if (e !== undefined && countryCode !== "") {
      const ph = e.replace("+" + countryCode, "");
      !ph && setPhErrorMessage("Please enter the valid Phone number");
    } else {
      setPhErrorMessage("Please enter the valid Phone number");
    }
  }
  function handleBlur(e: any) {
    setPhErrorMessage("");
    const len = e.target.value.split(" ").length;
    const c = e.target.value.split(" ");
    const cc = c[0].split("+");
    setCountryCode(cc[1]);
    const ph = e.target.value.substring(e.target.value.indexOf(" ") + 1);
    if (len > 1 && ph !== "") {
    } else {
      setPhErrorMessage("Please enter the valid Phone number");
    }
  }

  function getCountryShortNameByCallingCode(callingCode: any) {
    try {
      const phoneNumber = parsePhoneNumberFromString(`+${callingCode}`);
      if (!phoneNumber) {
        throw new Error("Invalid calling code");
      }

      const countryShortName = phoneNumber.country;
      return countryShortName || ("Unknown" as CountryCode);
    } catch (error) {
      console.error("Error parsing calling code:", error);
      return "Error" as CountryCode;
    }
  }

  const connect = (id: any, sid: any, schedule: any) => {
    localStorage.setItem("organization", userInfo.org_name);
    localStorage.setItem("org_data", JSON.stringify(userInfo));
    if (jdDetail) {
      localStorage.setItem("job_title", jdDetail["job_title"]);
      localStorage.setItem("jd", JSON.stringify(jdDetail));
    } else {
      localStorage.setItem("job_title", "");
    }
    localStorage.setItem("channel", id);
    localStorage.setItem("type", "1");
    localStorage.setItem("sid", sid);
    localStorage.setItem("take_ss", "flase");
    localStorage.setItem(
      "name",
      userInfo.first_name + " " + userInfo.last_name
    );
    localStorage.setItem("stType", "1");
    localStorage.setItem("schedule", JSON.stringify(schedule));
    var str = BaseUrl.substr(BaseUrl.lastIndexOf("/") + 1);
    var url = BaseUrl.replace(new RegExp(str), "");
    window.open(url + "screenitMeeting/#/meeting");
  };

  const checkingdate = (date: any, end: any) => {
    const currentDate = new Date();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const current_date = moment(String(currentDate)).format("YYYYMMDDHHmm");
    let scheduledate: any = moment.utc(date).subtract(5, "minutes").toDate();
    scheduledate = moment.utc(scheduledate).tz(timezone).format("YYYYMMDDHHmm");
    const endtime1 = moment.utc(end).add(1, "hours").toDate();
    const endtime = moment.utc(endtime1).tz(timezone).format("YYYYMMDDHHmm");
    if (current_date >= scheduledate && current_date < endtime) {
      return "yes";
    } else {
      return "no";
    }
  };

  const addInvitees = async () => {
    var reqData = {} as any;
    if (otherEmails.length !== 0) {
      var oe = otherEmails.join(",");
      reqData["invitees"] = oe;
    } else {
      const oes = document.getElementById(
        "other_email"
      ) as HTMLInputElement | null;
      if (oes) {
        reqData["invitees"] = oes.value;
      }
    }
    if (reqData["invitees"] === "") {
      toast("Add Email");
      return;
    }
    setOtherEmails([]);
    const res = await orgApi.addInvitees(schedule_id, reqData);
    res.data.success ? toast(res.data.msg) : toast(res.data.msg);
    showAddIniteesModel(false);
    setSchDetailModal(false);
    fetchCandidate();
  };

  const getSelfRating = async (candidate_id: any) => {
    const res = await orgApi.candidateSelfEveluation(candidate_id);
    if (res.data.candidate) {
      let pskil = res.data.candidate[0].u_p_skill;
      let ppskill = [] as any;
      for (let i = 0; i < pskil.length; i++) {
        ppskill.push({
          name: pskil[i].skill.name,
          skill_id: pskil[i].skill_id,
          self_rating: pskil[i].self_rating,
          year_of_experience: pskil[i].year_of_experience,
          month_of_experience: pskil[i].month_of_experience,
        });
      }
      setPrimary(ppskill);

      let sskil = res.data.candidate[0].u_s_skill;
      let psskill = [] as any;
      for (let i = 0; i < sskil.length; i++) {
        psskill.push({
          name: sskil[i].skill.name,
          skill_id: sskil[i].skill_id,
          self_rating: sskil[i].self_rating,
          year_of_experience: sskil[i].year_of_experience,
          month_of_experience: sskil[i].month_of_experience,
        });
      }
      setSecondary(psskill);
    }
    showCandidateRateModel(true);
  };

  const submitFeedback = async () => {
    if (!selectedFeedFile) {
      toast("Please attach the feedback");
      return;
    }
    if (manualFeedback.sit === 0) {
      toast("Please select the SIT score");
      return;
    }
    const formData = new FormData();
    formData.append("feedback", selectedFeedFile!);
    formData.append("feedback_video_url", manualFeedback.video_url);
    formData.append("sit", manualFeedback.sit.toString());
    const res = await orgApi.addManualFeedback(schedule_id, formData);
    if (res.data.success) {
      toast(res.data.msg);
      showGetFeedbackModal(false);
      fetchCandidate();
      if (state?.nextRound) {
        setSelectedType("NR");
        setMoveNextModal(true);
      }
    } else {
      toast(res.data.msg);
    }
  };

  const changeScope = async () => {
    const reqData = { scope: scheduleScope === "manual" };
    const res = await orgApi.changeScope(schedule_id, reqData);
    if (res.data.success) {
      fetchCandidate();
      showScopeConfirmationModel(false);
      toast(res.data.msg);
    } else {
      toast(res.data.msg);
    }
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      ></ToastContainer>
      {deleteResumeConfirmation && (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white shadow-lg rounded-sm rounded-br-3xl">
                <div className="sm:flex">
                  <div className="mt-1 text-center sm:text-left w-full">
                    <div className=" w-full mt-4 max-w-lg text-xs text-gray-500 font-normal">
                      Are you sure you want to delete the resume?
                    </div>

                    <div className=" gap-2 mt-3 sm:flex md:flex lg:flex flex-row justify-end">
                      <button
                        className=" mt-2 py-1 px-2.5 text-gray-800 outline-none border text-sm"
                        onClick={() => setDeleteResumeConfirmation(false)}
                      >
                        No
                      </button>
                      <button
                        className={
                          !isDisabled
                            ? "mt-2 py-1 px-2.5 text-white button-primary outline-none text-sm"
                            : "mt-2 py-1 px-2.5 text-white button-primary-tint outline-none text-sm"
                        }
                        onClick={() => deleteCandidate(schedule_id)}
                        disabled={isDisabled}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {availabilityModal && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-50"></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-full max-w-lg p-4 mx-auto bg-white shadow-lg rounded-sm rounded-br-3xl">
              <div className="sm:flex">
                <div className="mt-1 text-center sm:text-left w-full">
                  <div className="flex items-center justify-between border-b">
                    <h6 className="text-sm font-bold text-gray-800 mb-2">
                      Set Availability
                    </h6>
                  </div>
                  <div className=" w-full mt-4 max-w-lg grid grid-cols-2 gap-2">
                    <div className="mb-1 py-2">
                      <label className={Style.label}>
                        Select Date <b className="error">*</b>
                      </label>
                      <DatePicker
                        selected={available_datetime}
                        onChange={handleDateChange}
                        dateFormat="yyyy-MM-dd HH:mm:ss"
                        showTimeSelect
                        timeFormat="hh:mm a"
                        timeIntervals={30}
                        minDate={new Date()}
                        excludeTimes={S.generateDisabledTimes(
                          available_datetime
                        )}
                        isClearable
                        placeholderText="Select date and time"
                        className={Style.iInput}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      />
                      {datetime_error !== "" && (
                        <div className="error">{datetime_error}</div>
                      )}
                    </div>
                    <div className="mb-1 py-2">
                      <label className={Style.label}>
                        Select Time Zone <b className="error">*</b>
                      </label>
                      <select
                        onChange={(e) => handleChangeTZ(e)}
                        className="w-full border text-xs text-gray-700 border-gray-300  py-2.5 px-3 focus:outline-none focus:border-blue-500 rounded"
                      >
                        {timezone.map((x) => (
                          <option value={x.fullName}>{x.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-1 py-2 col-span-2">
                      <label className={Style.label}>Add Invitee</label>
                      <input
                        placeholder="Enter Email"
                        type="email"
                        id="other_email"
                        name="other_email"
                        onKeyDown={(e: any) => {
                          setOtherEmailError("");
                          if (e.key === "Enter") {
                            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                            const isValidEmail = emailRegex.test(
                              e.target.value
                            );
                            if (isValidEmail) {
                              setOtherEmails((prevItems: any) =>
                                prevItems.concat(e.target.value)
                              );
                              const input = document.getElementById(
                                "other_email"
                              ) as HTMLInputElement | null;
                              if (input) {
                                input.value = "";
                              }
                            } else {
                              setOtherEmailError("Enter valid email");
                            }
                          }
                        }}
                        className={Style.iInput}
                      ></input>
                      {otherEmailError !== "" && (
                        <div className="error">{otherEmailError}</div>
                      )}
                      <div className="my-2 mx-2 flex flex-col gap-2">
                        {otherEmails.map((e: any, ind1: number) => {
                          return (
                            <div className="flex justify-start items-center">
                              <p className="text-gray-500 font-normal text-xs py-1 px-2 bg-gray-100 border">
                                {e}
                              </p>
                              <button
                                className="bg-gray-300 border text-xs text-gray-800 py-2 px-2"
                                onClick={() => {
                                  setOtherEmails((prevState: any) =>
                                    prevState.filter(
                                      (_: any, ind: number) => ind !== ind1
                                    )
                                  );
                                }}
                              >
                                <CloseModal />
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className=" gap-2 mt-3 sm:flex md:flex lg:flex flex-row-reverse">
                    <button
                      className={
                        !isDisabled
                          ? "mt-2 py-1 px-2.5 text-white button-primary outline-none text-sm rounded-md"
                          : "mt-2 py-1 px-2.5 text-white button-primary-tint outline-none text-sm rounded-md"
                      }
                      onClick={() => setAvailability()}
                      disabled={isDisabled}
                    >
                      Submit
                    </button>
                    <button
                      className=" mt-2 py-1 px-2.5 text-gray-800 outline-none border text-sm rounded-md"
                      onClick={() => setAvailabilityModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {invitedIntModal && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-50"></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-full max-w-lg p-4 mx-auto bg-white shadow-lg rounded-sm rounded-br-3xl">
              <div className="sm:flex">
                <div className="mt-1 text-center sm:text-left w-full">
                  <div className="flex items-center justify-between border-b">
                    <h6 className="text-sm font-bold text-gray-800 mb-2">
                      Invited Interviewers
                    </h6>
                    <button
                      onClick={() => setInvitedIntModal(false)}
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      <CloseModal />
                    </button>
                  </div>
                  <div className="mt-3 mb-3 ">
                    {invited.length === 0 ? (
                      <p className="text-xs text-gray-600 font-normal">
                        The selected panels are already blocked for the same
                        timing .please choose different panels.
                      </p>
                    ) : (
                      <table className="table-fixed text-xs w-full">
                        <thead>
                          <tr>
                            <th className="w-1/2">
                              <p className="p-2">Interviewer Name</p>
                            </th>
                            <th className="w-1/2">
                              <p className="p-2">Status</p>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-gray-600">
                          {invited.map((v: any) => (
                            <tr className="bg-gray-50 border-b-2 border-white ">
                              <td>
                                <p className="p-2">
                                  {v.interviewer.candidate.first_name}{" "}
                                  {v.interviewer.candidate.last_name}
                                </p>
                              </td>
                              <td>
                                <p className="p-2">
                                  {v.select_tag === "false"
                                    ? "Declined"
                                    : "Pending"}
                                </p>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {schDetailModal && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-50"></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-full max-w-4xl p-4 mx-auto bg-white shadow-lg rounded-sm rounded-br-3xl">
              <div className="sm:flex">
                <div className="mt-1 text-center sm:text-left w-full">
                  <div className="flex items-center justify-between border-b">
                    <h6 className="text-sm font-bold text-gray-800 mb-2">
                      Schedule Details
                    </h6>
                    <button
                      onClick={() => setSchDetailModal(false)}
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      <CloseModal />
                    </button>
                  </div>
                  {schedule && jdDetail && (
                    <div className="mt-3 mb-3 ">
                      <div className="grid grid-cols-3 border-b">
                        <div className="col-span-1 flex flex-col gap-3 text-xs  my-2">
                          <div className="flex flex-col">
                            <span className="text-gray-400">Job Tittle</span>
                            <span className="text-xs font-semibold text-gray-600">
                              {jdDetail["job_title"]}
                            </span>
                          </div>
                          <div className="flex flex-col">
                            <span className="text-gray-400">Skillset</span>
                            <span className="text-xs font-semibold text-gray-600">
                              {S.getSkills(jdDetail["template"]["t_p_skill"])}
                            </span>
                          </div>
                          <div className="flex flex-col">
                            <span className="text-gray-400">
                              Interview Type
                            </span>
                            <span className="text-xs font-semibold text-gray-600">
                              {schedule.schedule[0].interview_type}
                            </span>
                          </div>
                        </div>
                        <div className="col-span-2 bg-gray-50 rounded p-2  my-2">
                          <p className="border-b text-sm text-gray-600 font-semibold mb-2">
                            Interviewer
                          </p>
                          <div className="grid grid-cols-2">
                            <div className="col-span-1 flex flex-col gap-2">
                              <div className="flex flex-row items-start gap-2">
                                <div className="h-8 w-8 bg-gray-100 rounded-full overflow-hidden">
                                  <div className="flex justify-center items-center h-8">
                                    <BsPersonFill color="grey" size={20} />
                                  </div>
                                </div>
                                <div className="text-xs">
                                  <p className="text-gray-800 font-normal">
                                    {
                                      schedule.schedule[0].interviewer.candidate
                                        .first_name
                                    }
                                  </p>
                                  <p className="text-gray-400 font-normal">
                                    {
                                      schedule.schedule[0].interviewer.candidate
                                        .designation
                                    }
                                  </p>
                                </div>
                              </div>
                              <span className="border rounded-sm border-primary py-1 px-2 text-xs text-primary font-normal max-w-max">
                                Experience :
                                {
                                  schedule.schedule[0].interviewer.candidate
                                    .year_of_experience
                                }
                                Years | ₹ {paid} / Interview
                              </span>
                            </div>
                            <div className="col-span-1 flex flex-col gap-1">
                              <div className="flex flex-row items-center gap-1 text-primary">
                                <BsCalendarFill /> &nbsp;
                                <div className="text-xs font-bold text-primary">
                                  {S.getDateTz(
                                    schedule.schedule[0].utc_starttime,
                                    schedule.schedule[0].candidate_tz
                                  )}
                                </div>
                              </div>
                              <div className="flex flex-row items-center gap-1 text-primary">
                                <BsClockFill /> &nbsp;
                                <div className="text-xs font-bold text-primary">
                                  {S.getTimeTz(
                                    schedule.schedule[0].utc_starttime,
                                    schedule.schedule[0].candidate_tz
                                  )}{" "}
                                  to{" "}
                                  {S.getEndTimeTz(
                                    schedule.schedule[0].utc_starttime,
                                    schedule.schedule[0].candidate_tz
                                  )}{" "}
                                  {S.getSrtTz(
                                    schedule.schedule[0].candidate_tz
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full p-2 my-2 bg-gray-50">
                        <div className="col-span-2 bg-gray-50 rounded  my-2">
                          <p className="border-b text-sm text-gray-600 font-semibold mb-2">
                            Overview
                          </p>
                          <div className="grid grid-cols-3 gap-3 text-xs mt-2">
                            <div className="flex flex-col">
                              <span className="text-gray-400">
                                Year of Experience
                              </span>
                              <span className="text-xs font-semibold text-gray-600">
                                {jdDetail["year_of_experience"]} years{" "}
                                {jdDetail["month_of_experience"]} months
                              </span>
                            </div>
                            <div className="flex flex-col">
                              <span className="text-gray-400">
                                Interview Type
                              </span>
                              <span className="text-xs font-semibold text-gray-600">
                                {jdDetail["interview_type"]}
                              </span>
                            </div>
                            <div className="flex flex-col">
                              <span className="text-gray-400">
                                Primary Skills
                              </span>
                              <span className="text-xs font-semibold text-gray-600">
                                {S.getSkills(jdDetail["template"]["t_p_skill"])}
                              </span>
                            </div>
                            <div className="flex flex-col col-span-3">
                              <span className="text-gray-400">
                                Job Description
                              </span>
                              <span className="text-xs font-semibold text-gray-600 w-full truncate">
                                <pre>{jdDetail["template"]["description"]}</pre>
                              </span>
                            </div>
                            <div className="flex flex-col">
                              <span className="text-gray-400">
                                Comments / Remarks
                              </span>
                              <span className="text-xs font-semibold text-gray-600">
                                {jdDetail["remarks"]}
                              </span>
                            </div>
                            <div className="flex flex-col">
                              <span className="text-gray-400">Meeting ID</span>
                              <span className="text-xs font-semibold text-gray-600">
                                {schedule.schedule[0]["meeting_id"]}
                              </span>
                            </div>
                            {schedule.ssn !== 0 && (
                              <div className="flex flex-col">
                                <span className="text-gray-400">
                                  SSN Number
                                </span>
                                <span className="text-xs font-semibold text-gray-600">
                                  {schedule.ssn}
                                </span>
                              </div>
                            )}
                            {schedule.aadhar !== 0 && (
                              <div className="flex flex-col">
                                <span className="text-gray-400">
                                  Aadhar Number
                                </span>
                                <span className="text-xs font-semibold text-gray-600">
                                  {schedule.aadhar}
                                </span>
                              </div>
                            )}
                            {schedule.schedule[0]["invitees"] !== "" && (
                              <div className="flex flex-col col-span-3">
                                <span className="text-gray-400">Invitees</span>
                                <span className="text-xs font-semibold text-gray-600">
                                  {schedule.schedule[0]["invitees"]}
                                  <button
                                    hidden
                                    onClick={() => {
                                      if (
                                        schedule.schedule[0]["invitees"] !==
                                          null &&
                                        schedule.schedule[0]["invitees"] !== ""
                                      ) {
                                        var si =
                                          schedule.schedule[0][
                                            "invitees"
                                          ].split(",");
                                        setOtherEmails(si);
                                      }
                                      setScheduleId(schedule.schedule[0]["id"]);
                                      showAddIniteesModel(true);
                                    }}
                                    className="text-primary font-semibold text-xs mx-3"
                                  >
                                    +Add
                                  </button>
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="w-full p-2 my-2 bg-gray-50">
                        <div className="col-span-2 bg-gray-50 rounded  my-2">
                          <p className="border-b text-sm text-gray-600 font-semibold mb-2">
                            Candidate Info
                          </p>
                          <div className="grid grid-cols-3 gap-3 text-xs mt-2">
                            <div className="flex flex-col">
                              <span className="text-gray-400">
                                Candidate Name
                              </span>
                              <span className="text-xs font-semibold text-gray-600">
                                {schedule.first_name} {schedule.last_name}
                              </span>
                            </div>
                            <div className="flex flex-col">
                              <span className="text-gray-400">
                                Candidate Email
                              </span>
                              <span className="text-xs font-semibold text-gray-600">
                                {schedule.email}
                              </span>
                            </div>
                            <div className="flex flex-col">
                              <span className="text-gray-400">
                                Candidate Mobile
                              </span>
                              <span className="text-xs font-semibold text-gray-600">
                                {schedule.mobile}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showFeedbackModal && schedule && jdDetail && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-50"></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-full max-w-3xl p-4 mx-auto bg-white shadow-lg rounded-sm rounded-br-3xl">
              <div className="sm:flex">
                <div className="mt-1 text-center sm:text-left w-full">
                  <div className="flex items-center justify-between border-b">
                    <h6 className="text-sm font-bold text-gray-800 mb-2">
                      Results
                    </h6>
                    <button
                      onClick={() => setFeedbackModal(false)}
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      <CloseModal />
                    </button>
                  </div>
                  <div className=" w-full mt-4 max-w-3xl">
                    <div className=" grid grid-cols-3 gap-1 px-5 py-4 items-center mb-4">
                      {/* <div className="col-span-1 flex justify-center">
                        <div className="h-20 w-20 mr-1 bg-gray-100 rounded-full overflow-hidden">
                          <img
                            src="https://randomuser.me/api/portraits/women/68.jpg"
                            alt="user_profile_photo"
                            className="h-full w-full object-cover"
                          ></img>
                        </div>
                      </div> */}
                      <div className="col-span-1 ">
                        <div className="flex flex-col justify-between gap-2 h-24">
                          <div>
                            <p className="text-xs text-gray-400 font-thin">
                              Candidate Name
                            </p>
                            <p className="text-xs text-gray-900 font-semibold truncate">
                              {schedule.first_name} {schedule.last_name}
                            </p>
                          </div>
                          <div>
                            <p className="text-xs text-gray-400 font-thin">
                              Designation
                            </p>
                            <p className="text-xs text-gray-900 font-semibold truncate">
                              {schedule.designation}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="flex flex-col justify-between gap-2 h-24">
                          <div>
                            <p className="text-xs text-gray-400 font-thin">
                              Feedback Status
                            </p>
                            {schedule["schedule"][0]["feedback_status"] ===
                            "pending" ? (
                              <p className="text-xs text-red-500 font-semibold truncate">
                                {"Pending"}
                              </p>
                            ) : (
                              <p className="text-xs text-primary font-semibold truncate">
                                {"Completed"}
                              </p>
                            )}
                          </div>
                          <div>
                            <p className="text-xs text-gray-400 font-thin">
                              Interview Status
                            </p>
                            <p className="text-xs text-primary font-semibold truncate">
                              {schedule["schedule"][0]["feedback_status"] !==
                              "Completed"
                                ? "Completed"
                                : schedule["schedule"][0]["feedback"][0][
                                    "interview_status"
                                  ]}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="flex flex-col justify-between gap-3 h-24">
                          <div>
                            <p className="text-xs text-gray-400 font-thin">
                              Email Id
                            </p>
                            <p className="text-xs text-gray-900 font-semibold truncate">
                              {schedule.email}
                            </p>
                          </div>
                          <div>
                            <p className="text-xs text-gray-400 font-thin">
                              Interview Duration
                            </p>
                            <p className="text-xs text-gray-900 font-semibold truncate">
                              {schedule["schedule"][0]["interview_duration"]}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full p-2 my-2 bg-gray-50">
                      <div className="col-span-2 bg-gray-50 rounded  my-2">
                        <p className="border-b text-sm text-gray-800 font-semibold mb-2">
                          Interview Detail
                        </p>
                        <div className="grid grid-cols-3 gap-2 text-xs mt-2">
                          <div className="flex flex-col">
                            <span className="text-gray-400">
                              Interviewer Name
                            </span>
                            <span className="text-xs font-semibold text-gray-600">
                              {
                                schedule["schedule"][0]["interviewer"][
                                  "candidate"
                                ]["first_name"]
                              }{" "}
                              {
                                schedule["schedule"][0]["interviewer"][
                                  "candidate"
                                ]["last_name"]
                              }
                            </span>
                          </div>
                          <div className="flex flex-col">
                            <span className="text-gray-400">
                              Interview Date
                            </span>
                            <span className="text-xs font-semibold text-gray-600">
                              {format(
                                schedule.schedule[0].utc_starttime,
                                "dd MMM yyyy"
                              )}
                            </span>
                          </div>
                          <div className="flex flex-col">
                            <span className="text-gray-400">
                              Interview Time
                            </span>
                            <span className="text-xs font-semibold text-gray-600">
                              {format(
                                schedule.schedule[0].utc_starttime,
                                "hh:mm a"
                              )}{" "}
                              to{" "}
                              {format(
                                schedule.schedule[0].utc_endtime,
                                "hh:mm a"
                              )}{" "}
                              {getTz(schedule.schedule[0].candidate_tz)}
                            </span>
                          </div>
                          <div className="flex flex-col">
                            <span className="text-gray-400">
                              Mode of Interview
                            </span>
                            <span className="text-xs font-semibold text-gray-600">
                              {jdDetail["interview_type"]}
                            </span>
                          </div>
                          <div className="flex flex-col">
                            <span className="text-gray-400">Meeting ID</span>
                            <span className="text-xs font-semibold text-gray-600">
                              {schedule.schedule[0]["meeting_id"]}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {schedule["schedule"][0]["feedback_status"] ===
                      "Completed" &&
                      schedule["schedule"][0].feedback.length > 0 &&
                      schedule["schedule"][0]["feedback"][0][
                        "interview_status"
                      ] !== "Completed" && (
                        <div className="w-full p-2 my-2 bg-gray-50">
                          <div className="bg-gray-50 rounded grid grid-cols-4 gap-3">
                            <p className="text-xs text-gray-800 font-semibold mb-2 col-span-1">
                              Reason
                            </p>
                            <p className="text-xs text-gray-600 font-normal mb-2 col-span-3">
                              {schedule["schedule"][0]["feedback"][0][
                                "candidate_no_show"
                              ]
                                ? "Candidate no show"
                                : schedule["schedule"][0]["feedback"][0][
                                    "proxy"
                                  ]
                                ? "Candidate Proxy"
                                : schedule["schedule"][0]["feedback"][0][
                                    "reason"
                                  ]["name"]}
                            </p>
                            <p className="text-xs text-gray-800 font-semibold mb-2 col-span-1">
                              Comments
                            </p>
                            <p className="text-xs text-gray-600 font-normal mb-2 col-span-3">
                              {schedule["schedule"][0]["feedback"][0][
                                "candidate_no_show"
                              ]
                                ? "Candidate no show"
                                : schedule["schedule"][0]["feedback"][0][
                                    "proxy"
                                  ]
                                ? "Candidate Proxy"
                                : schedule["schedule"][0]["feedback"][0][
                                    "incomplete_reason_cmt"
                                  ]}
                            </p>
                          </div>
                        </div>
                      )}
                    {schedule["schedule"][0].feedback_status === "Completed" &&
                      schedule["schedule"][0].feedback.length > 0 && (
                        <>
                          <div className="text-sm font-medium text-center text-gray-500  dark:text-gray-400">
                            <ul
                              className="flex justify-start -mb-px"
                              id="default-tab"
                              data-tabs-toggle="#default-tab-content"
                              role="tablist"
                            >
                              <li className="me-2">
                                <a
                                  onClick={() => setDefaultPage("video")}
                                  className={
                                    defaultPage === "video"
                                      ? "inline-flex items-center justify-center  p-2 text-blue-600 bg-primary rounded active font-semibold text-xs text-white dark:border-blue-500"
                                      : "inline-flex items-center justify-center  p-2 border-b-2 border-transparent rounded-t-lg hover:text-gray-900 hover:border-gray-300 dark:hover:text-gray-300 text-xs"
                                  }
                                >
                                  Interview Video
                                </a>
                              </li>
                              <li className="me-2">
                                <a
                                  onClick={() => setDefaultPage("rate")}
                                  className={
                                    defaultPage === "rate"
                                      ? "inline-flex items-center justify-center  p-2 text-blue-600 bg-primary rounded active font-semibold text-xs text-white dark:border-blue-500"
                                      : "inline-flex items-center justify-center  p-2 border-b-2 border-transparent rounded-t-lg hover:text-gray-900 hover:border-gray-300 dark:hover:text-gray-300 text-xs"
                                  }
                                >
                                  Rate this Interview
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div id="default-tab-content">
                            {defaultPage === "video" ? (
                              <div className="flex justify-start mt-2">
                                <div className="my-2 flex flex-col gap-2">
                                  {S.getFileExtension(
                                    schedule["schedule"][0].video_url
                                  ) != "mp4" ? (
                                    <ReactHlsPlayer
                                      src={schedule["schedule"][0].video_url}
                                      autoPlay={false}
                                      controls={true}
                                      width="750"
                                      height="500"
                                    />
                                  ) : (
                                    <video
                                      src={schedule["schedule"][0].video_url}
                                      autoPlay={false}
                                      controls={true}
                                      width="750"
                                      height="500"
                                    />
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="flex flex-row justify-center items-center">
                                {schedule["schedule"][0].s_rating.length ===
                                0 ? (
                                  <div className="p-5 flex flex-col justify-center gap-2 w-1/3">
                                    <StarRatings
                                      rating={int_rate}
                                      changeRating={(rate: number) => {
                                        setIntRate(rate);
                                      }}
                                      numberOfStars={5}
                                      name="rating"
                                      starRatedColor="blue"
                                      starHoverColor="blue"
                                      starDimension="18px"
                                      starSpacing="5px"
                                    />
                                    <textarea
                                      value={int_comment}
                                      onChange={(e) =>
                                        setIntComment(e.target.value)
                                      }
                                      placeholder="Comments"
                                      className={
                                        "w-full border py-2 px-3 outline-none rounded "
                                      }
                                    ></textarea>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        submitRating(schedule["schedule"][0].id)
                                      }
                                      className="mt-2 py-1 px-2.5 text-white button-primary outline-none text-sm"
                                    >
                                      Submit
                                    </button>
                                  </div>
                                ) : (
                                  <div className="p-5 flex flex-col justify-center gap-2 w-1/3">
                                    <StarRatings
                                      rating={
                                        schedule["schedule"][0].s_rating[0]
                                          .rating
                                      }
                                      changeRating={(_) => {
                                        // setIntRate(rate);
                                      }}
                                      numberOfStars={5}
                                      name="rating"
                                      starRatedColor="blue"
                                      starHoverColor="blue"
                                      starDimension="18px"
                                      starSpacing="5px"
                                    />
                                    <p className="text-xs font-normal text-gray-600">
                                      {
                                        schedule["schedule"][0].s_rating[0]
                                          .comments
                                      }
                                    </p>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          <div className="p-3 border-t">
                            <div className="flex justify-end">
                              <button
                                onClick={() =>
                                  S.viewFeedback(schedule["schedule"][0].id)
                                }
                                className="py-1 px-2.5 text-white button-primary outline-none text-sm"
                              >
                                View Feedback
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {uploadResumeModal && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-50"></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-full max-w-lg p-4 mx-auto bg-white shadow-lg rounded-sm rounded-br-3xl">
              <div className="sm:flex">
                <div className="mt-1 text-center sm:text-left w-full">
                  <div className="flex items-center justify-between border-b">
                    <h6 className="text-sm font-bold text-gray-800 mb-2">
                      Upload Resume
                    </h6>
                  </div>
                  <div className="mt-1">
                    <form className="max-w-lg mx-auto mt-4">
                      <div className="flex items-center w-full border-primary rounded ">
                        <div className="cursor-pointer w-full">
                          <label className="cursor-pointer w-full flex justify-start">
                            {selectedFile.length > 0 && (
                              <div className="flex justify-center items-center border-r overflow-hidden font-semibold text-xs p-2 bg-primary text-white w-1/4">
                                <BsUpload color="white" size={10} /> &nbsp;
                                Change
                              </div>
                            )}
                            {selectedFile.length === 0 && (
                              <div className="flex justify-center items-center border-r  overflow-hidden font-semibold text-xs p-2 bg-primary text-white w-1/4">
                                <BsUpload color="white" size={10} /> &nbsp;
                                Upload
                              </div>
                            )}
                            <div className="flex justify-start items-center w-2/4">
                              &nbsp;
                              {selectedFile.length !== 0 && (
                                <div className="text-sm px-3 text-gray-400 truncate">
                                  {selectedFile[0]?.name}
                                </div>
                              )}
                              <input
                                type="file"
                                className="hidden"
                                multiple={true}
                                onChange={handleImageChange}
                              ></input>
                            </div>
                          </label>
                        </div>
                      </div>
                      {fileError !== "" && (
                        <div className="error mb-2">{fileError}</div>
                      )}
                      <div
                        className="mt-1 text-xs text-gray-500"
                        id="user_avatar_help"
                      >
                        Supported format (.pdf, .docx), Size (Max 3MB)
                      </div>
                      {selectedFile.length > 0 && (
                        <>
                          {selectedFile.map((resume: any, index: number) => (
                            <>
                              <div
                                className="flex flex-row justify-between my-2"
                                key={index}
                              >
                                <div className="flex flex-row items-center gap-2 justify-start">
                                  <SwitchComp
                                    resumeType={resumeType}
                                    setResumeType={setResumeType}
                                    index={index}
                                    on={"Auto"}
                                    off={"Manual"}
                                  ></SwitchComp>
                                  <span className=" text-sm font-medium text-gray-600">
                                    {resume.name}
                                  </span>
                                </div>
                                <button
                                  onClick={() => removeResume(index)}
                                  type="button"
                                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                  <CloseModal />
                                </button>
                              </div>
                            </>
                          ))}
                        </>
                      )}
                    </form>

                    <div className=" gap-2 mt-3 sm:flex md:flex lg:flex flex-row justify-end">
                      <button
                        className=" mt-2 py-1 px-2.5 text-gray-800 outline-none border text-sm"
                        onClick={() => setUploadResumeModal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className={
                          selectedFile.length > 0 && !isDisabled
                            ? " button-primary mt-2 py-1 px-2.5 text-white outline-none text-sm"
                            : " bg-indigo-300 mt-2 py-1 px-2.5 text-white outline-none text-sm"
                        }
                        onClick={() => uploadResume()}
                        disabled={
                          selectedFile.length > 0 && !isDisabled ? false : true
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {editCandiModal && (
        <div className="fixed inset-0 z-10 overflow-y-auto  ">
          <div className="fixed inset-0 w-full h-full bg-black opacity-50"></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-full max-w-xl p-4 mx-auto bg-white shadow-lg rounded-sm rounded-br-3xl">
              <div className="sm:flex">
                <div className="mt-1 text-center sm:text-left w-full">
                  <div className="flex items-center justify-between border-b">
                    <h6 className="text-sm font-bold text-gray-800 mb-2">
                      Edit Candidate
                    </h6>
                  </div>
                  <div className="mt-1">
                    <form
                      className="max-w-lg mx-auto mt-4"
                      onSubmit={formik.handleSubmit}
                    >
                      <div className="grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-2 ">
                        <div className="sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5">
                          <label className={Style.label}>
                            First Name<b className="error">*</b>
                          </label>
                          <input
                            placeholder="First Name"
                            type="text"
                            id="name"
                            name="name"
                            value={formik.values.name}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            className={
                              formik.touched.name && formik.errors.name
                                ? Style.iError
                                : Style.iInput
                            }
                          ></input>
                          {formik.touched.name && formik.errors.name && (
                            <div className="error">{formik.errors.name}</div>
                          )}
                        </div>
                        <div className="sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5">
                          <label className={Style.label}>Last Name</label>
                          <input
                            placeholder="Last Name"
                            type="text"
                            id="lastName"
                            name="lastName"
                            value={formik.values.lastName}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            className={Style.iInput}
                          ></input>
                        </div>
                        <div className="sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5">
                          <label className={Style.label}>
                            Email<b className="error">*</b>
                          </label>
                          <input
                            placeholder="Email"
                            type="text"
                            id="email"
                            name="email"
                            value={formik.values.email}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            className={
                              formik.touched.email && formik.errors.email
                                ? Style.iError
                                : Style.iInput
                            }
                          ></input>
                          {formik.touched.email && formik.errors.email && (
                            <div className="error">{formik.errors.email}</div>
                          )}
                        </div>
                        <div className="sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5">
                          <label className={Style.label}>
                            Mobile<b className="error">*</b>
                          </label>
                          <PhoneInput
                            defaultCountry={defaultCC}
                            international
                            countryCallingCodeEditable={false}
                            initialValueFormat="national"
                            name="phone"
                            value={phone}
                            onBlur={handleBlur}
                            onChange={handlechange}
                            className={
                              phErrorMsg !== "" ? Style.iError : Style.iInput
                            }
                            autoComplete="off"
                          />
                          {phErrorMsg && (
                            <div className="error">{phErrorMsg}</div>
                          )}
                        </div>
                        <div className="sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5">
                          <label className={Style.label}>
                            Aadar Number(Last 4 Digits)
                          </label>
                          <input
                            disabled={formik.values.ssn === "" ? false : true}
                            placeholder="Aadar Number"
                            type="text"
                            id="aadhar"
                            name="aadhar"
                            value={formik.values.aadhar}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            className={
                              formik.values.ssn === ""
                                ? Style.iInput
                                : Style.iInputDisable
                            }
                          ></input>
                          {formik.touched.aadhar && formik.errors.aadhar && (
                            <div className="error">{formik.errors.aadhar}</div>
                          )}
                        </div>
                        <div className="sm:m-2 my-2 lg:mx-5 md:mx-5 xl:mx-5">
                          <label className={Style.label}>
                            SSN Number(Last 4 Digits)
                          </label>
                          <input
                            disabled={
                              formik.values.aadhar === "" ? false : true
                            }
                            placeholder="SSN Number"
                            type="text"
                            id="ssn"
                            name="ssn"
                            value={formik.values.ssn}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            className={
                              formik.values.aadhar === ""
                                ? Style.iInput
                                : Style.iInputDisable
                            }
                          ></input>
                          {formik.touched.ssn && formik.errors.ssn && (
                            <div className="error">{formik.errors.ssn}</div>
                          )}
                        </div>
                      </div>
                      <div className="gap-2 mt-3 sm:flex md:flex lg:flex flex-row justify-end">
                        <button
                          className="mt-2 py-1 px-2.5 text-gray-800 outline-none border text-sm"
                          onClick={() => setEditCandiModal(false)}
                        >
                          Cancel
                        </button>
                        <button
                          disabled={isDisabled}
                          type="submit"
                          className={
                            !isDisabled
                              ? "button-primary mt-2 py-1 px-2.5 text-white outline-none text-sm"
                              : "button-primary-tint mt-2 py-1 px-2.5 text-white outline-none text-sm"
                          }
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {schModal && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-50"></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-1/2 max-w-3xl p-4 mx-auto bg-white shadow-lg rounded-sm rounded-br-3xl">
              <div className="sm:flex">
                <div className="mt-1 text-center sm:text-left w-full">
                  <div className="flex items-center justify-between border-b">
                    <h6 className="text-sm font-bold text-gray-800 mb-2">
                      Reason for
                      {schUpdate === "cancel" ? "Cancellation" : "Reschedule"}
                    </h6>
                    <button
                      onClick={() => setSchModal(false)}
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      <CloseModal />
                    </button>
                  </div>
                  {cancelReason.length > 0 && (
                    <>
                      <select
                        id="reason"
                        name="reason"
                        placeholder="Reason"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        className="w-full border border-gray-200 py-2 px-3 focus:outline-none focus:border-gray-300 rounded mt-4"
                      >
                        <option value="">{"Select Reason*"}</option>
                        {cancelReason.map((x: any) => (
                          <option value={x.id}>{x.name}</option>
                        ))}
                      </select>
                      <textarea
                        placeholder="Enter the Comments"
                        className="w-full border py-2 my-2 px-3 outline-none rounded"
                        onChange={(e) => setReasonCmd(e.target.value)}
                        value={reasonCmd}
                      ></textarea>
                      {schUpdate !== "cancel" ? (
                        <>
                          <div className=" w-full mt-4 max-w-lg grid grid-cols-2 gap-2">
                            <div className="mb-1 py-2">
                              <label className={Style.label}>
                                Select Date <b className="error">*</b>
                              </label>
                              <DatePicker
                                selected={available_datetime}
                                onChange={handleDateChange}
                                dateFormat="yyyy-MM-dd HH:mm:ss"
                                showTimeSelect
                                timeFormat="hh:mm a"
                                timeIntervals={30}
                                minDate={new Date()}
                                excludeTimes={S.generateDisabledTimes(
                                  available_datetime
                                )}
                                isClearable
                                placeholderText="Select date and time"
                                className={Style.iInput}
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                              />
                              {datetime_error !== "" && (
                                <div className="error">{datetime_error}</div>
                              )}
                            </div>
                            <div className="mb-1 py-2">
                              <label className={Style.label}>
                                Select Time Zone <b className="error">*</b>
                              </label>
                              <select
                                onChange={(e) => handleChangeTZ(e)}
                                className="w-full border text-xs text-gray-700 border-gray-300  py-2.5 px-3 focus:outline-none focus:border-blue-500 rounded"
                              >
                                {timezone.map((x) => (
                                  <option value={x.fullName}>{x.name}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <button
                            className={
                              !isDisabled
                                ? "mt-2 py-1 px-2.5 text-white button-primary outline-none text-sm rounded-md font-medium float-right"
                                : "mt-2 py-1 px-2.5 text-white button-primary-tint outline-none text-sm rounded-md font-medium float-right"
                            }
                            onClick={() => reSchedule()}
                            disabled={isDisabled}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        <button
                          className={
                            !isDisabled
                              ? "mt-2 py-1 px-2.5 text-white button-primary outline-none text-sm rounded-md float-right"
                              : "mt-2 py-1 px-2.5 text-white button-primary-tint outline-none text-sm rounded-md float-right"
                          }
                          onClick={() => cancelSchedule()}
                          disabled={isDisabled}
                        >
                          Submit
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showHistory && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-50"></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-1/2 max-w-sm p-4 mx-auto bg-white shadow-lg rounded-sm rounded-br-3xl">
              <div className="sm:flex">
                <div className="mt-1 text-center sm:text-left w-full">
                  <div className="flex items-center justify-between border-b">
                    <h6 className="text-sm font-bold text-gray-800 mb-2">
                      Feedback History
                    </h6>
                    <button
                      onClick={() => setShowHistory(false)}
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      <CloseModal />
                    </button>
                  </div>
                  <table className="table-auto w-full mt-2">
                    <thead>
                      <tr>
                        <th align="center" className="text-xs text-gray-700">
                          Round Name
                        </th>
                        {S.getOrgDomain() !== "nam-it.com" && (
                          <th align="center" className="text-xs text-gray-700">
                            SIT Score
                          </th>
                        )}
                        <th align="center" className="text-xs text-gray-700">
                          Feedback Link
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {history.map((x: any) => (
                        <tr>
                          <td className="text-xs p-2 text-center">
                            {x.round.name}
                          </td>
                          {S.getOrgDomain() !== "nam-it.com" && (
                            <td className="text-xs text-primary text-center font-semibold cursor-pointer p-2">
                              {x.feedback.length > 0
                                ? x.feedback[0].sit_score
                                : x.sit}
                            </td>
                          )}
                          <td
                            onClick={() => {
                              if (x.feedback.length > 0) {
                                S.viewFeedback(x.id);
                              } else {
                                window.open(x.feedback_url);
                              }
                            }}
                            className="underline text-blue-500 font-normal text-center text-xs p-2 cursor-pointer"
                          >
                            Link
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {moveNextModal && (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white shadow-lg rounded-sm rounded-br-3xl">
                <div className="sm:flex">
                  <div className="mt-1 text-center sm:text-left w-full">
                    <div className=" w-full mt-4 max-w-lg text-xs text-gray-500 font-normal">
                      {selectedType === "NR" &&
                        "Are you sure you want to move the candidate to the next round?"}
                      {selectedType === "Submitted" &&
                        "Are you sure you want to submit the candidate to the client?"}
                      {selectedType === "Offered" &&
                        "Are you sure you want to extend the job offer to the candidate?"}
                      {selectedType === "Interview" &&
                        "Are you sure you want to move the candidate to the client interview round?"}
                      {selectedType === "ToJoin" &&
                        "Are you sure you want to change the status?"}
                      {selectedType === "Declined" &&
                        "Are you sure you want to change the status?"}
                      {selectedType === "Joined" &&
                        "Are you sure you want tochange the status?"}
                    </div>
                    {selectedType === "NR" && (
                      <div className="mb-1 py-2 col-span-1">
                        <label className={Style.label}>
                          Choose the schedule type for the next round
                        </label>
                        <div className="flex flex-row gap-5 justify-center items-center my-1">
                          <div className="flex items-center gap-2">
                            <input
                              checked={manualFeedback.next_type === "auto"}
                              id="default-radio-2"
                              type="radio"
                              name="default-radio"
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                              onChange={() => {
                                setManualFeedback((prevObject: any) => ({
                                  ...prevObject,
                                  next_type: "auto",
                                }));
                              }}
                            ></input>
                            <label className={Style.label}>Auto</label>
                          </div>
                          <div className="flex items-center gap-2">
                            <input
                              checked={manualFeedback.next_type === "manual"}
                              id="default-radio-1"
                              type="radio"
                              name="default-radio"
                              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                              onChange={() => {
                                setManualFeedback((prevObject: any) => ({
                                  ...prevObject,
                                  next_type: "manual",
                                }));
                              }}
                            ></input>
                            <label className={Style.label}>Manual</label>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className=" gap-2 mt-3 sm:flex md:flex lg:flex flex-row-reverse">
                      <button
                        className={
                          !isDisabled
                            ? "mt-2 py-1 px-2.5 text-white button-primary outline-none text-sm"
                            : "mt-2 py-1 px-2.5 text-white button-primary-tint outline-none text-sm"
                        }
                        onClick={() =>
                          moveToNextSubmit(schedule_id, selectedType)
                        }
                        disabled={isDisabled}
                      >
                        Confirm
                      </button>
                      <button
                        className=" mt-2 py-1 px-2.5 text-gray-800 outline-none border text-sm"
                        onClick={() => setMoveNextModal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {addIniteesModel && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-50"></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-full max-w-lg p-4 mx-auto bg-white shadow-lg rounded-sm rounded-br-3xl">
              <div className="sm:flex">
                <div className="mt-1 text-center sm:text-left w-full">
                  <div className="flex items-center justify-between border-b">
                    <h6 className="text-sm font-bold text-gray-800 mb-2">
                      Add Invitees
                    </h6>
                  </div>
                  <div className=" w-full mt-4 max-w-lg grid grid-cols-1 gap-2">
                    <div className="mb-1 py-2 col-span-1">
                      <label className={Style.label}>Add Invitee</label>
                      <input
                        placeholder="Enter Email"
                        type="email"
                        id="other_email"
                        name="other_email"
                        onKeyDown={(e: any) => {
                          setOtherEmailError("");
                          if (e.key === "Enter") {
                            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                            const isValidEmail = emailRegex.test(
                              e.target.value
                            );
                            if (isValidEmail) {
                              setOtherEmails((prevItems: any) =>
                                prevItems.concat(e.target.value)
                              );
                              const input = document.getElementById(
                                "other_email"
                              ) as HTMLInputElement | null;
                              if (input) {
                                input.value = "";
                              }
                            } else {
                              setOtherEmailError("Enter valid email");
                            }
                          }
                        }}
                        className={Style.iInput}
                      ></input>
                      {otherEmailError !== "" && (
                        <div className="error">{otherEmailError}</div>
                      )}
                      <div className="my-2 mx-2 flex flex-col gap-2">
                        {otherEmails.map((e: any, ind1: number) => {
                          return (
                            <div className="flex justify-start items-center">
                              <p className="text-gray-500 font-normal text-xs py-1 px-2 bg-gray-100 border">
                                {e}
                              </p>
                              <button
                                className="bg-gray-300 border text-xs text-gray-800 py-2 px-2"
                                onClick={() => {
                                  setOtherEmails((prevState: any) =>
                                    prevState.filter(
                                      (_: any, ind: number) => ind !== ind1
                                    )
                                  );
                                }}
                              >
                                <CloseModal />
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className=" gap-2 mt-3 sm:flex md:flex lg:flex flex-row-reverse">
                    <button
                      className={
                        "mt-2 py-1 px-2.5 text-white button-primary outline-none text-sm rounded-md"
                      }
                      onClick={() => addInvitees()}
                    >
                      Submit
                    </button>
                    <button
                      className=" mt-2 py-1 px-2.5 text-gray-800 outline-none border text-sm rounded-md"
                      onClick={() => showAddIniteesModel(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {candidateRateModel && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-50"></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-full max-w-2xl p-4 mx-auto bg-white shadow-lg rounded-sm rounded-br-3xl">
              <div className="sm:flex">
                <div className="mt-1 text-center sm:text-left w-full">
                  <div className="flex items-center justify-between border-b">
                    <h6 className="text-sm font-bold text-gray-800 mb-2">
                      Candidate Self Evaluation
                    </h6>
                    <button
                      onClick={() => showCandidateRateModel(false)}
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      <CloseModal />
                    </button>
                  </div>
                  <div className="mt-3 mb-3 ">
                    <div
                      className="w-full overflow-y-auto"
                      style={{ height: "25rem" }}
                    >
                      <h1 className="text-xs font-semibold mb-2">
                        Required Technical Skills
                      </h1>
                      {primary.length === 0 && (
                        <span className="text-xs font-medium text-gray-300">
                          No data found
                        </span>
                      )}
                      {primary.map((val: any) => {
                        return (
                          <div className="flex flex-col items-start w-full mb-1 border-b">
                            <span className=" text-xs font-medium">
                              {val.name}
                            </span>
                            <div className="flex flex-row justify-between items-center my-2 w-full">
                              <StarRatings
                                rating={val.self_rating}
                                changeRating={(rate: number) => {
                                  console.log(rate);
                                }}
                                numberOfStars={5}
                                name="rating"
                                starRatedColor="orange"
                                starHoverColor="orange"
                                starDimension="15px"
                                starSpacing="2px"
                              />
                              <div className="flex flex-row justify-end items-center gap-2 text-xs">
                                <p className="text-gray-800 font-semibold">
                                  Experience :{" "}
                                </p>
                                <span className="text-gray-600 font-semibold">
                                  {val.year_of_experience}
                                </span>{" "}
                                <span className="text-gray-400 font-normal">
                                  Year(s)
                                </span>
                                <span className="text-gray-600 font-semibold">
                                  {val.month_of_experience}
                                </span>{" "}
                                <span className="text-gray-400 font-normal">
                                  Month(s)
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <h1 className="text-xs mt-6 font-semibold mb-5">
                        Preferred Technical Skills
                      </h1>
                      {secondary.length === 0 && (
                        <span className="text-xs font-medium text-gray-300">
                          No data found
                        </span>
                      )}
                      {secondary.map((val: any) => {
                        return (
                          <div className="flex flex-col items-start w-full mb-1 border-b">
                            <span className=" text-xs font-medium">
                              {val.name}
                            </span>
                            <div className="flex flex-row justify-between items-center my-2 w-full">
                              <StarRatings
                                rating={val.self_rating}
                                changeRating={(rate: number) => {
                                  console.log(rate);
                                }}
                                numberOfStars={5}
                                name="rating"
                                starRatedColor="orange"
                                starHoverColor="orange"
                                starDimension="15px"
                                starSpacing="2px"
                              />
                              <div className="flex flex-row justify-end items-center gap-2 text-xs">
                                <p className="text-gray-800 font-semibold">
                                  Experience :{" "}
                                </p>
                                <span className="text-gray-600 font-semibold">
                                  {val.year_of_experience}
                                </span>{" "}
                                <span className="text-gray-400 font-normal">
                                  Year(s)
                                </span>
                                <span className="text-gray-600 font-semibold">
                                  {val.month_of_experience}
                                </span>{" "}
                                <span className="text-gray-400 font-normal">
                                  Month(s)
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {scopeConfirmationModel && (
        <>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div className="relative w-full max-w-lg p-4 mx-auto bg-white shadow-lg rounded-sm rounded-br-3xl">
                <div className="sm:flex">
                  <div className="mt-1 text-center sm:text-left w-full">
                    <div className=" w-full mt-4 max-w-lg text-xs text-gray-500 font-normal">
                      Are you sure you want to confirm the edit? The scope can
                      only be modified once.
                      <b>
                        The schedule will be{" "}
                        {scheduleScope === "manual" ? "Auto" : "Manual"} after
                        the edit.
                      </b>
                    </div>

                    <div className=" gap-2 mt-3 sm:flex md:flex lg:flex flex-row-reverse">
                      <button
                        className="mt-2 py-1 px-2.5 text-white button-primary outline-none text-sm"
                        onClick={() => {
                          changeScope();
                        }}
                      >
                        Confirm
                      </button>
                      <button
                        className=" mt-2 py-1 px-2.5 text-gray-800 outline-none border text-sm"
                        onClick={() => showScopeConfirmationModel(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {getFeedbackModal && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="fixed inset-0 w-full h-full bg-black opacity-50"></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className="relative w-full max-w-lg p-4 mx-auto bg-white shadow-lg rounded-sm rounded-br-3xl">
              <div className="sm:flex">
                <div className="mt-1 text-center sm:text-left w-full">
                  <div className="flex items-center justify-between border-b">
                    <h6 className="text-sm font-bold text-gray-800 mb-2">
                      Add Feedback
                    </h6>
                  </div>
                  <div className=" w-full mt-4 max-w-lg grid grid-cols-1 gap-2">
                    <div className="mb-1 py-2 col-span-1">
                      <label className={Style.label}>Feedback</label>
                      <div className="flex items-center w-full border-primary rounded ">
                        <div className="cursor-pointer  w-full">
                          <label className="cursor-pointer w-full flex justify-start">
                            {selectedFeedFile && (
                              <div className="flex justify-center items-center border-r overflow-hidden font-semibold text-xs p-2 bg-primary text-white w-1/4">
                                <BsUpload color="white" size={10} /> &nbsp;
                                Change
                              </div>
                            )}
                            {!selectedFeedFile && (
                              <div className="flex justify-center items-center border-r  overflow-hidden font-semibold text-xs p-2 bg-primary text-white w-1/4">
                                <BsUpload color="white" size={10} /> &nbsp;
                                Upload
                              </div>
                            )}
                            <div className="flex justify-start items-center w-2/4 ">
                              &nbsp;
                              {selectedFeedFile && (
                                <div className="text-sm px-3 text-gray-400 truncate">
                                  {selectedFeedFile?.name}
                                </div>
                              )}
                              <input
                                type="file"
                                className="hidden"
                                multiple={false}
                                onChange={handleFeedbackChange}
                              ></input>
                            </div>
                          </label>
                        </div>
                      </div>
                      {fileError !== "" ? (
                        <div className="error mb-2">{fileError}</div>
                      ) : null}
                      <div
                        className="mt-1 text-xs text-gray-500"
                        id="user_avatar_help"
                      >
                        Supported format (.pdf, .docx, .png, .jpg, .jpeg), Size
                        (Max 3MB)
                      </div>
                    </div>
                    <div className="mb-1 py-2 col-span-1">
                      <label className={Style.label}>Video Link</label>
                      <input
                        placeholder="Enter URL"
                        type="text"
                        id="video_url"
                        name="video_url"
                        value={manualFeedback.video_url}
                        onChange={(e: any) => {
                          setManualFeedback((prevObject: any) => ({
                            ...prevObject,
                            video_url: e.target.value,
                          }));
                        }}
                        className={Style.iInput}
                      ></input>
                    </div>
                    <div className="mb-1 py-2 col-span-1">
                      <label className={Style.label}>SIT Score</label>
                      <div className="flex flex-row gap-1">
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number) => (
                          <button
                            type="button"
                            onClick={() => {
                              setManualFeedback((prevObject: any) => ({
                                ...prevObject,
                                sit: number,
                              }));
                            }}
                            className={
                              number <= manualFeedback.sit
                                ? "flex items-center justify-center w-12 h-9 text-white text-sm font-semibold button-primary border rounded-md mb-3 "
                                : "flex items-center justify-center w-12 h-9 text-white text-sm font-semibold bg-gray-200 border rounded-md mb-3 "
                            }
                          >
                            {number}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className=" gap-2 mt-3 sm:flex md:flex lg:flex flex-row-reverse">
                    <button
                      className={
                        "mt-2 py-1 px-2.5 text-white button-primary outline-none text-sm rounded-md"
                      }
                      onClick={() => {
                        submitFeedback();
                      }}
                    >
                      Submit
                    </button>
                    <button
                      className=" mt-2 py-1 px-2.5 text-gray-800 outline-none border text-sm rounded-md"
                      onClick={() => showGetFeedbackModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="overflow-y-auto" style={{ height: "84vh" }}>
        <div className="flex flex-col justify-center mr-1">
          <div className="flex items-center justify-between border-b">
            <h6 className="text-sm font-bold text-gray-800 mb-2">
              <div className="flex flex-row items-center">
                <p
                  className="cursor-pointer text-primary"
                  onClick={() => navigate(-3)}
                >
                  {"Dashboard "}
                </p>
                <p>&nbsp;{" / "}&nbsp;</p>
                <p
                  className="cursor-pointer text-primary"
                  onClick={() => navigate(-2)}
                >
                  {" Job Description"}
                </p>
                <p>&nbsp;{" / "}&nbsp;</p>
                <p
                  className="cursor-pointer text-primary"
                  onClick={() => navigate(-1)}
                >
                  {" Upload Candidates"}
                </p>
                <p>&nbsp;{" / "}&nbsp;</p>
                <p>{" Profiles"}</p>
              </div>
            </h6>
          </div>
          {jdDetail && (
            <div className="bg-gray-100 p-4 flex flex-row justify-between gap-4 items-center h-24 px-5 mb-3 grid grid-cols-10">
              <div className="flex flex-col col-span-3">
                <h6 className="text-md font-semibold text-gray-600 truncate mr-4 mb-1">
                  {jdDetail["job_title"]}
                </h6>
                <div className="text-xs font-normal text-gray-400 truncate mr-4">
                  {jdDetail["unique_id"]}
                </div>
              </div>
              <div className="flex flex-col col-span-2">
                <div className="text-xs font-semibold text-gray-800 mb-1">
                  Skills
                </div>
                <div
                  title={S.getSkills(jdDetail["template"]["t_p_skill"])}
                  className="text-xs font-normal text-gray-400 truncate"
                >
                  {S.getSkills(jdDetail["template"]["t_p_skill"])}
                </div>
              </div>
              <div className="flex flex-col col-span-1 items-center">
                <div className="text-xs font-semibold text-gray-800 mb-1">
                  {jdDetail["profile"]}
                </div>
                <div className="text-xs font-normal text-gray-400">
                  Profiles
                </div>
              </div>
              <div className="flex flex-col col-span-1 items-center">
                <div className="text-xs font-semibold text-gray-800 mb-1">
                  {jdDetail["round"][0]["new"]}
                </div>
                <div className="text-xs font-normal text-gray-400">New</div>
              </div>
              <div className="flex flex-col col-span-1 items-center">
                <div className="text-xs font-semibold text-gray-800 mb-1">
                  {jdDetail["round"][0]["scheduled"]}
                </div>
                <div className="text-xs font-normal text-gray-400">
                  Scheduled
                </div>
              </div>
              <div className="flex flex-col col-span-1 items-center">
                <div className="text-xs font-semibold text-gray-800 mb-1">
                  {jdDetail["round"][0]["completed"]}
                </div>
                <div className="text-xs font-normal text-gray-400">
                  Completed
                </div>
              </div>
              <div className="flex flex-col col-span-1 items-center">
                <div className="text-xs font-semibold text-gray-800 mb-1">
                  {jdDetail["round"][0]["selected"]}
                </div>
                <div className="text-xs font-normal text-gray-400">
                  Selected
                </div>
              </div>
            </div>
          )}
          <div className="grid grid-cols-4 gap-3 mt-4">
            <div className="col-span-1">
              <div className="bg-gray-100 rounded-lg flex flex-col border  p-3">
                <div className="flex items-center justify-between  mb-4">
                  <h6 className="text-xs font-bold text-gray-800">Filter By</h6>
                  <button
                    type="button"
                    onClick={() => clearFilter()}
                    className="text-primary text-xs font-thin bg-transparent rounded-lg 2xl:text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                  >
                    Clear
                  </button>
                </div>
                <div className="flex flex-col gap-2 mb-2">
                  <div className="text-xs font-bold text-gray-800">Status</div>
                  <select
                    id="status"
                    name="status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    className="w-full border border-gray-200 py-2 px-3 focus:outline-none focus:border-gray-300 rounded"
                  >
                    <option value="">All</option>
                    <option value="New">New</option>
                    <option value="Scheduled">Scheduled</option>
                    <option value="Completed">Completed</option>
                    <option value="Selected">Selected</option>
                    <option value="Offered">Offered</option>
                    <option value="Submitted">Client Submit</option>
                  </select>
                </div>
                <div className="flex flex-col gap-2 mb-2">
                  <div className="text-xs font-bold text-gray-800">Date</div>
                  <div className="flex flex-row gap-1">
                    <DatePicker
                      selected={from}
                      onChange={handleFromDateChange}
                      dateFormat="dd MMM, yyyy"
                      isClearable
                      placeholderText="Select From Date"
                      className="w-full border border-gray-200 py-2 px-3 focus:outline-none focus:border-gray-300 rounded"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                    <DatePicker
                      selected={to}
                      onChange={handleToDateChange}
                      dateFormat="dd MMM, yyyy"
                      isClearable
                      placeholderText="Select To Date"
                      className="w-full border border-gray-200 py-2 px-3 focus:outline-none focus:border-gray-300 rounded"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-3 flex flex-col">
              <div className="flex flex-row justify-between">
                <div className="w-2/4  mr-5">
                  <form className="searchForm">
                    <input
                      placeholder="Search.."
                      type="search"
                      id="search"
                      name="search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      className={"w-full border py-2 px-3 outline-none rounded"}
                    ></input>
                    <button className="searchButton" type="button">
                      Search
                    </button>
                  </form>
                </div>
                <div className="w-full">
                  <div className="gap-2 flex flex-row justify-end">
                    <button
                      type="button"
                      className="mt-2 py-1.5 px-3.5 text-primary outline-none border-primary text-xs rounded-md"
                      onClick={() => fetchCandidate()}
                    >
                      <AiOutlineSync />
                    </button>
                    {jdDetail && jdDetail["round"][0]["level"] === 1 && (
                      <button
                        type="button"
                        className="mt-2 py-1.5 px-4 text-white outline-none bg-primary rounded-sm flex flex-row gap-2 text-xs font-medium	"
                        onClick={() => openUploadResume()}
                      >
                        <BsUpload /> Candidate
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div style={{ height: "49vh" }}>
                {tempCandidateList.length === 0 &&
                  candidateList.length === 0 && (
                    <div className="flex flex-col justify-center items-center my-16 gap-1">
                      <img
                        src={nodata}
                        alt="user_profile_photo"
                        className="h-32 w-32 object-cover"
                      ></img>
                      <p className="text-xs font-thin text-gray-400">
                        No Profile Found.
                      </p>
                    </div>
                  )}
                <div className="gap-2 mt-4 overflow-y-auto h-full flex flex-col">
                  {(status === "" || status === "New") &&
                    tempCandidateList.map((value: any) => (
                      <div
                        key={value.id}
                        className=" flex flex-col gap-1 border border-gray-200 rounded-md bg-gray-50 p-1"
                      >
                        <div className="justify-between grid grid-cols-2">
                          {/* Status */}
                          <div className="mx-2 my-1 col-span-1">
                            <div className="min-w-max max-w-max px-1  py-1  text-xs font-semibold text-green-500 flex justify-center">
                              <p className="cursor-pointer">New</p>
                            </div>
                          </div>
                          <div className="flex flex-row justify-end col-span-1">
                            <div className="flex items-center py-1 p-3">
                              <button
                                type="button"
                                onClick={() => openEditcandidate(value)}
                                className="py-0.5 px-1 text-gray-800 bg-white outline-none border flex rounded-sm item-center gap-2 text-xs font-normal"
                              >
                                <BsPencil />
                              </button>
                            </div>
                          </div>
                        </div>

                        {/* Info */}
                        <div className="grid grid-cols-3 items-center">
                          <div className="flex flex-col px-3 gap-1 w-full col-span-1">
                            <h6 className="text-md font-semibold text-gray-600 truncate">
                              {value.first_name} {value.last_name}
                            </h6>
                            <div className="flex flex-row items-center gap-1  text-gray-600">
                              <BsEnvelope /> &nbsp;
                              <div className="text-xs font-normal text-gray-600">
                                {value.email}
                              </div>
                            </div>
                            <div className="flex flex-row items-center gap-1  text-gray-600">
                              <BsFillTelephoneFill /> &nbsp;
                              <div className="text-xs font-normal text-gray-600">
                                {value.mobile}
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col px-3 gap-1 w-full col-span-1"></div>
                          <div className="flex flex-col px-3 gap-1 w-full col-span-1"></div>
                        </div>
                      </div>
                    ))}
                  {candidateList.map((value: any, index: number) => {
                    return (
                      <div
                        key={value.id}
                        className=" flex flex-col gap-1 border border-gray-200 rounded-md bg-gray-50 p-1"
                      >
                        <div className="justify-between grid grid-cols-4">
                          {/* Status */}
                          <div className="mx-2 my-1 col-span-2">
                            {(value.schedule[0].status ===
                              "Schedule Initiated" ||
                              value.schedule[0].status ===
                                "Reschedule Initiated") && (
                              <div className="min-w-max max-w-max px-3 bg-yellow-600 py-1 rounded-sm text-xs font-semibold text-white flex justify-center">
                                <p
                                  onClick={() =>
                                    viewInvitedInterviewers(
                                      value.schedule[0].id
                                    )
                                  }
                                  className="cursor-pointer"
                                >
                                  {value.schedule[0].status ===
                                  "Schedule Initiated"
                                    ? "Schedule Request Pending"
                                    : "Reschedule Request Pending"}
                                </p>
                              </div>
                            )}
                            {((value.schedule[0].feedback_status ===
                              "Completed" &&
                              value.schedule[0].feedback.length > 0 &&
                              value.schedule[0].feedback[0].interview_status ===
                                "Completed") ||
                              (!value.schedule[0].scope &&
                                (value.schedule[0].status === "Selected" ||
                                  value.schedule[0].status === "Submitted" ||
                                  value.schedule[0].status === "Offered" ||
                                  value.schedule[0].status === "Interview" ||
                                  value.schedule[0].status === "ToJoin" ||
                                  value.schedule[0].status === "Declined" ||
                                  value.schedule[0].status === "Joined"))) && (
                              <div className="flex flex-row justify-start gap-3 w-full">
                                {S.getOrgDomain() !== "nam-it.com" ? (
                                  <div
                                    className="text-primary font-semibold cursor-pointer"
                                    onClick={() =>
                                      value.schedule[0].scope
                                        ? openFeedbackDetail(value)
                                        : window.open(
                                            value.schedule[0].feedback_url
                                          )
                                    }
                                  >
                                    SIT{" "}
                                    {value.schedule[0].scope
                                      ? value.schedule[0].feedback[0].sit_score
                                      : value.schedule[0].sit}
                                  </div>
                                ) : (
                                  <div
                                    className="text-primary font-semibold cursor-pointer"
                                    onClick={() =>
                                      value.schedule[0].scope
                                        ? openFeedbackDetail(value)
                                        : window.open(
                                            value.schedule[0].feedback_url
                                          )
                                    }
                                  >
                                    Completed
                                  </div>
                                )}
                                {value.schedule[0].status === "Rejected" ? (
                                  <div className="min-w-max max-w-max px-3 mx-2 bg-red-500 py-1 rounded-sm text-xs font-semibold text-white flex justify-center">
                                    <p className="cursor-pointer">Rejected</p>
                                  </div>
                                ) : value.schedule[0].status === "Selected" ? (
                                  <div className="min-w-max max-w-max px-3 mx-2 bg-green-600 py-1 rounded-sm text-xs font-semibold text-white flex justify-center">
                                    <p className="cursor-pointer">Selected</p>
                                  </div>
                                ) : (
                                  <p></p>
                                )}
                              </div>
                            )}
                            {value.schedule[0].status === "New" && (
                              <div className="min-w-max max-w-max px-1  py-1  text-xs font-semibold text-green-500 flex justify-center">
                                <p className="cursor-pointer">New</p>
                              </div>
                            )}
                            {value.schedule[0].status === "Cancelled" && (
                              <div className="min-w-max max-w-max px-3 bg-red-500 py-1 rounded-sm text-xs font-semibold text-white flex justify-center">
                                <p className="cursor-pointer">Open</p>
                              </div>
                            )}
                            {value.schedule[0].status ===
                              "Schedule ReInitiated" &&
                              (value.schedule[0].sub_status ===
                                "Schedule Initiated" ||
                                value.schedule[0].sub_status ===
                                  "Reschedule Initiated") && (
                                <div className="min-w-max max-w-max px-3 bg-red-500 py-1 rounded-sm text-xs font-semibold text-white flex justify-center">
                                  <p
                                    onClick={() =>
                                      viewInvitedInterviewers(
                                        value.schedule[0].id
                                      )
                                    }
                                    className="cursor-pointer"
                                  >
                                    Declined
                                  </p>
                                </div>
                              )}
                            {value.schedule[0].status === "Scheduled" && (
                              <div className="min-w-max max-w-max px-3 bg-primary py-1 rounded-sm text-xs font-semibold text-white flex justify-center">
                                <p
                                  onClick={() => openScheduleDetail(value)}
                                  className="cursor-pointer"
                                >
                                  Scheduled
                                </p>
                              </div>
                            )}
                            {value.schedule[0].status === "Rescheduled" && (
                              <div className="min-w-max max-w-max px-3 bg-primary py-1 rounded-sm text-xs font-semibold text-white flex justify-center">
                                <p
                                  onClick={() => openScheduleDetail(value)}
                                  className="cursor-pointer"
                                >
                                  Rescheduled
                                </p>
                              </div>
                            )}
                            {value.schedule[0].status === "Completed" &&
                              value.schedule[0].feedback_status ===
                                "pending" && (
                                <div className="min-w-max max-w-max px-3 bg-yellow-300 py-1 rounded-sm text-xs font-semibold text-black flex justify-center">
                                  <p
                                    onClick={() => openFeedbackDetail(value)}
                                    className="cursor-pointer"
                                  >
                                    Feedback Pending
                                  </p>
                                </div>
                              )}
                            {value.schedule[0].status === "Completed" &&
                              value.schedule[0].feedback_status ===
                                "Completed" &&
                              value.schedule[0].feedback.length > 0 &&
                              value.schedule[0].feedback[0].interview_status !==
                                "Completed" && (
                                <div className="min-w-max max-w-max px-3 bg-red-500 py-1 rounded-sm text-xs font-semibold text-white flex justify-center">
                                  <p
                                    onClick={() => openFeedbackDetail(value)}
                                    className="cursor-pointer"
                                  >
                                    InComplete
                                  </p>
                                </div>
                              )}
                          </div>
                          <div className="mx-2 my-1 col-span-1">
                            <h1
                              className={
                                (value.schedule[0].status === "New" ||
                                  value.schedule[0].status ===
                                    "Schedule Initiated") &&
                                !value.schedule[0].edited
                                  ? "col-span-1 text-xs font-normal text-gray-500 "
                                  : "col-span-1 text-xs font-normal text-gray-500 disabled-div"
                              }
                            >
                              <div
                                className="w-16 h-5 flex items-center justify-between bg-gray-200 cursor-pointer"
                                onClick={() => {
                                  setScheduleId(value.schedule[0].id);
                                  setScheduleScope(
                                    value.schedule[0].scope ? "auto" : "manual"
                                  );
                                  showScopeConfirmationModel(true);
                                }}
                              >
                                <p
                                  className={
                                    "text-gray-800 font-semibold m-1" +
                                    (!value.schedule[0].scope
                                      ? " transform translate-x-5  "
                                      : " ")
                                  }
                                  style={{ fontSize: "10px" }}
                                >
                                  {value.schedule[0].scope ? "Auto" : "Manual"}
                                </p>
                                <div
                                  className={
                                    "h-5 w-6 shadow-sm transform duration-300 ease-in-out" +
                                    (value.schedule[0].scope
                                      ? " bg-primary "
                                      : " bg-yellow-500 transform -translate-x-11 ")
                                  }
                                ></div>
                              </div>
                            </h1>
                          </div>
                          <div className="flex flex-row justify-end col-span-1">
                            {value.schedule[0].status !== "Completed" &&
                              value.schedule[0].status !== "Scheduled" &&
                              value.schedule[0].status !== "Rescheduled" &&
                              value.schedule[0].status !== "Selected" &&
                              value.schedule[0].status !== "Rejected" &&
                              value.schedule[0].status !== "Submitted" &&
                              value.schedule[0].status !== "Offered" &&
                              value.schedule[0].status !== "Cancelled" &&
                              value.schedule[0].level === 1 && (
                                <button
                                  onClick={() => {
                                    setScheduleId(value.schedule[0].id);
                                    setDeleteResumeConfirmation(true);
                                  }}
                                  className="flex items-center text-rose-600 font-bold px-2"
                                >
                                  <BsTrash3 />
                                </button>
                              )}
                            <div className="flex items-center py-1 p-3">
                              {value.schedule[0].level > 1 ||
                              value.schedule.length > 1 ? (
                                <button
                                  onClick={() =>
                                    scheduleHistory(
                                      value.schedule[0].candidate_id
                                    )
                                  }
                                  className="flex py-0.5 px-1 text-sky-700 bg-white outline-none border rounded-sm item-center gap-2 text-xs font-normal"
                                >
                                  <BsMenuButtonFill /> Feedback
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  onClick={() => openEditcandidate(value)}
                                  className="py-0.5 px-1 text-gray-800 bg-white outline-none border flex rounded-sm item-center gap-2 text-xs font-normal"
                                >
                                  <BsPencil />
                                </button>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* Info */}
                        <div className="grid grid-cols-4  items-center">
                          <div className="flex flex-col px-3 gap-1 w-full col-span-1">
                            <h6
                              className="text-md font-semibold text-gray-600 truncate cursor-pointer"
                              onClick={() => getSelfRating(value.id)}
                            >
                              {value.first_name} {value.last_name}
                            </h6>
                            <div className="flex flex-row items-center gap-1  text-gray-600 w-full">
                              <BsEnvelope /> &nbsp;
                              <div className="text-xs font-normal text-gray-600 truncate w-4/5">
                                {value.email}
                              </div>
                            </div>
                            <div className="flex flex-row items-center gap-1  text-gray-600 w-full">
                              <BsFillTelephoneFill /> &nbsp;
                              <div className="text-xs font-normal text-gray-600 truncate">
                                +{value.mobile_country_code} {value.mobile}
                              </div>
                            </div>
                            <div className=" underline  text-blue-500 ml-5">
                              <a
                                href={value.resume[0].url}
                                target="_blank"
                                className="text-xs font-normal text-blue-500"
                              >
                                Download
                              </a>
                            </div>
                          </div>

                          <div className="flex flex-col px-3 gap-1 w-full col-span-1">
                            {value.schedule[0].scope ? (
                              <div className="flex flex-row items-center gap-1  text-gray-600">
                                <BsCalendar /> &nbsp;
                                <div className="text-xs font-normal text-gray-600">
                                  {value.schedule[0].status === "New" ||
                                  value.schedule[0].status === "Cancelled"
                                    ? "-"
                                    : S.getDateTz(
                                        value.schedule[0].utc_starttime,
                                        value.schedule[0].s_tz
                                      )}
                                </div>
                              </div>
                            ) : (
                              <div className="flex flex-row items-center gap-1  text-gray-600">
                                <BsCalendar /> &nbsp;
                                <div className="text-xs font-normal text-gray-600">
                                  {!value.schedule[0].available_datetime
                                    ? "-"
                                    : S.getDateTz(
                                        value.schedule[0].utc_starttime,
                                        value.schedule[0].s_tz
                                      )}
                                </div>
                              </div>
                            )}

                            {value.schedule[0].scope ? (
                              <div className="flex flex-row items-center gap-1  text-gray-600">
                                <BsClock /> &nbsp;
                                <div className="text-xs font-normal text-gray-600">
                                  {value.schedule[0].status === "New" ||
                                  value.schedule[0].status === "Cancelled" ? (
                                    "-"
                                  ) : (
                                    <>
                                      {S.getTimeTz(
                                        value.schedule[0].utc_starttime,
                                        value.schedule[0].s_tz
                                      )}{" "}
                                      to{" "}
                                      {S.getEndTimeTz(
                                        value.schedule[0].utc_starttime,
                                        value.schedule[0].s_tz
                                      )}{" "}
                                      {S.getSrtTz(value.schedule[0].s_tz)}
                                    </>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="flex flex-row items-center gap-1  text-gray-600">
                                <BsClock /> &nbsp;
                                <div className="text-xs font-normal text-gray-600">
                                  {!value.schedule[0].available_datetime ? (
                                    "-"
                                  ) : (
                                    <>
                                      {S.getTimeTz(
                                        value.schedule[0].utc_starttime,
                                        value.schedule[0].s_tz
                                      )}{" "}
                                      to{" "}
                                      {S.getEndTimeTz(
                                        value.schedule[0].utc_starttime,
                                        value.schedule[0].s_tz
                                      )}{" "}
                                      {S.getSrtTz(value.schedule[0].s_tz)}
                                    </>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="flex flex-col items-center px-3 gap-1 w-full col-span-2">
                            {userInfo.master_id === null && (
                              <div className="mt-1 mb-1 w-full flex justify-end gap-1">
                                <BsPersonFill size={13} />
                                <p className="text-xs font-thin text-gray-400">
                                  Recruiter:
                                </p>{" "}
                                <p className="text-xs font-semibold text-gray-800">
                                  {value.schedule[0].org.first_name +
                                    " " +
                                    value.schedule[0].org.last_name}
                                </p>
                              </div>
                            )}
                            {!value.schedule[0].scope &&
                            value.schedule[0].status !== "Selected" &&
                            value.schedule[0].status !== "Submitted" &&
                            value.schedule[0].status !== "Offered" &&
                            value.schedule[0].status !== "Interview" &&
                            value.schedule[0].status !== "ToJoin" &&
                            value.schedule[0].status !== "Declined" &&
                            value.schedule[0].status !== "Joined" ? (
                              <div className="mt-1 mb-1 w-full flex justify-end gap-4">
                                {!value.schedule[0].scope &&
                                  (value.schedule[0].available_datetime ? (
                                    <div className=" flex flex-row justify-around cursor-pointer gap-1">
                                      <p
                                        className="text-sm font-semibold text-rose-600 border px-2 py-1 "
                                        onClick={() => {
                                          setCancelScheduleModal(true);
                                          getCancelReason();
                                          setScheduleId(value.schedule[0].id);
                                        }}
                                      >
                                        Cancel
                                      </p>
                                      <p
                                        className="text-sm font-semibold text-green-600 border px-2 py-1"
                                        onClick={() => {
                                          setIsReschedule(
                                            !value.schedule[0].scope &&
                                              value.schedule[0]
                                                .available_datetime
                                          );
                                          setManualAvailabilityModal(true);
                                          setSchUpdate("reschedule");
                                          getCancelReason();
                                          setScheduleId(value.schedule[0].id);
                                          setRescheduleData({
                                            event_interviewer:
                                              value.schedule[0]
                                                .event_interviewer,
                                            event_org:
                                              value.schedule[0].event_org,
                                          });
                                        }}
                                      >
                                        Reschedule
                                      </p>
                                    </div>
                                  ) : (
                                    <button
                                      className={`border px-2 py-1 text-sm font-semibold flex flex-row justify-center ${
                                        value.schedule[0].available_datetime
                                          ? "text-gray-400"
                                          : "text-primary"
                                      }`}
                                      onClick={() => {
                                        setScheduleId(value.schedule[0].id);
                                        setManualAvailabilityModal(true);
                                        setIsReschedule(false);
                                      }}
                                      disabled={
                                        value.schedule[0].available_datetime
                                      }
                                    >
                                      Set Availability
                                    </button>
                                  ))}
                                <button
                                  className={
                                    "text-sm font-semibold text-primary border px-2 py-1"
                                  }
                                  type="button"
                                  onClick={() => {
                                    setManualFeedback((prev: any) => ({
                                      ...prev,
                                      sit: 0,
                                      video_url: "",
                                      next_type: "auto",
                                    }));
                                    setSelectedFeedFile(undefined);
                                    setScheduleId(value.schedule[0].id);
                                    showGetFeedbackModal(true);
                                  }}
                                >
                                  Give Feedback
                                </button>
                              </div>
                            ) : (
                              <div className="mt-1 mb-1 w-full flex justify-end">
                                {value.email !== null &&
                                  value.email !== "" &&
                                  value.email !== undefined &&
                                  (value.schedule[0].status === "New" ||
                                    value.schedule[0].status === "Cancelled" ||
                                    (value.schedule[0].status ===
                                      "Schedule ReInitiated" &&
                                      (value.schedule[0].sub_status ===
                                        "Schedule Initiated" ||
                                        value.schedule[0].sub_status ===
                                          "Reschedule Initiated"))) && (
                                    <div
                                      className="border px-2 py-1 text-sm font-semibold text-primary flex flex-row justify-center cursor-pointer"
                                      onClick={() =>
                                        openSetAvailability(
                                          value.schedule[0].id
                                        )
                                      }
                                    >
                                      Set Availability
                                    </div>
                                  )}
                                {value.schedule[0].status === "Completed" &&
                                  value.schedule[0].feedback_status ===
                                    "Completed" &&
                                  value.schedule[0].feedback.length > 0 &&
                                  value.schedule[0].feedback[0]
                                    .interview_status !== "Completed" && (
                                    <div
                                      className="border px-2 py-1 text-sm font-semibold text-primary flex flex-row justify-center cursor-pointer"
                                      onClick={() =>
                                        openSetAvailability(
                                          value.schedule[0].id
                                        )
                                      }
                                    >
                                      Set Availability
                                    </div>
                                  )}
                                {value.schedule[0].status ===
                                  "Schedule Initiated" ||
                                value.schedule[0].status ===
                                  "Reschedule Initiated" ? (
                                  <div
                                    className=" border px-2 py-1 text-sm font-semibold text-primary flex flex-row justify-center cursor-pointer"
                                    onClick={() =>
                                      scheduleAvailability(value.schedule[0].id)
                                    }
                                  >
                                    Schedule
                                  </div>
                                ) : (
                                  value.schedule[0].status === "Completed" &&
                                  value.schedule[0].feedback_status ===
                                    "Completed" &&
                                  value.schedule[0].feedback.length > 0 &&
                                  value.schedule[0].feedback[0]
                                    .interview_status === "Completed" && (
                                    <div className=" flex flex-row justify-around cursor-pointer">
                                      <p
                                        className="border px-2 py-1 text-sm font-semibold text-green-600 mr-1"
                                        onClick={() =>
                                          selectCandidate(
                                            value.schedule[0].id,
                                            "selected"
                                          )
                                        }
                                      >
                                        Select
                                      </p>
                                      <p
                                        className="border px-2 py-1 text-sm font-semibold text-rose-600"
                                        onClick={() =>
                                          selectCandidate(
                                            value.schedule[0].id,
                                            "rejected"
                                          )
                                        }
                                      >
                                        Reject
                                      </p>
                                    </div>
                                  )
                                )}
                                {value.schedule[0].status === "Scheduled" && (
                                  <div className=" flex flex-row justify-around cursor-pointer gap-1">
                                    <p
                                      className="text-sm font-semibold text-rose-600 border px-2 py-1 "
                                      onClick={() => {
                                        setSchModal(true);
                                        setSchUpdate("cancel");
                                        getCancelReason();
                                        setScheduleId(value.schedule[0].id);
                                      }}
                                    >
                                      Cancel
                                    </p>
                                    <p
                                      className="text-sm font-semibold text-green-600 border px-2 py-1"
                                      onClick={() => {
                                        setSchModal(true);
                                        setSchUpdate("reschedule");
                                        getCancelReason();
                                        setScheduleId(value.schedule[0].id);
                                      }}
                                    >
                                      Reschedule
                                    </p>
                                    <button
                                      className={
                                        checkingdate(
                                          value.schedule[0].utc_starttime,
                                          value.schedule[0].utc_starttime
                                        ) === "yes"
                                          ? "text-sm font-semibold text-primary border px-2 py-1 "
                                          : "text-sm font-semibold text-blue-200 border px-2 py-1 "
                                      }
                                      type="button"
                                      disabled={
                                        checkingdate(
                                          value.schedule[0].utc_starttime,
                                          value.schedule[0].utc_starttime
                                        ) === "no"
                                      }
                                      onClick={() => {
                                        connect(
                                          value.schedule[0].meeting_id,
                                          value.schedule[0].id,
                                          value.schedule[0]
                                        );
                                      }}
                                    >
                                      Connect
                                    </button>
                                  </div>
                                )}
                                {value.schedule[0].status === "Rescheduled" && (
                                  <div className=" flex flex-row justify-around cursor-pointer gap-1">
                                    <p
                                      className="text-sm font-semibold text-rose-600 border px-2 py-1 "
                                      onClick={() => {
                                        setSchModal(true);
                                        setSchUpdate("cancel");
                                        getCancelReason();
                                        setScheduleId(value.schedule[0].id);
                                      }}
                                    >
                                      Cancel
                                    </p>
                                    <button
                                      className={
                                        checkingdate(
                                          value.schedule[0].utc_starttime,
                                          value.schedule[0].utc_starttime
                                        ) === "yes"
                                          ? "text-sm font-semibold text-primary border px-2 py-1 "
                                          : "text-sm font-semibold text-blue-200 border px-2 py-1 "
                                      }
                                      type="button"
                                      disabled={
                                        checkingdate(
                                          value.schedule[0].utc_starttime,
                                          value.schedule[0].utc_starttime
                                        ) === "no"
                                      }
                                      onClick={() => {
                                        connect(
                                          value.schedule[0].meeting_id,
                                          value.schedule[0].id,
                                          value.schedule[0]
                                        );
                                      }}
                                    >
                                      Connect
                                    </button>
                                  </div>
                                )}
                                {(value.schedule[0].status === "Selected" ||
                                  value.schedule[0].status === "Submitted" ||
                                  value.schedule[0].status === "Offered" ||
                                  value.schedule[0].status === "Interview" ||
                                  value.schedule[0].status === "ToJoin" ||
                                  value.schedule[0].status === "Declined" ||
                                  value.schedule[0].status === "Joined") && (
                                  <>
                                    {value.schedule[0].status ===
                                      "Selected" && (
                                      <div className=" flex flex-row justify-around cursor-pointer gap-1">
                                        {state?.nextRound && (
                                          <button
                                            className="text-sm font-semibold text-primary border px-2 py-1 h-8"
                                            onClick={() => {
                                              setScheduleId(
                                                value.schedule[0].id
                                              );
                                              setSelectedType("NR");
                                              setMoveNextModal(true);
                                            }}
                                          >
                                            Next Round
                                          </button>
                                        )}
                                      </div>
                                    )}
                                    {value.schedule[0].status ===
                                      "Submitted" && (
                                      <>
                                        <div className=" flex flex-row justify-around cursor-pointer h-8 py-1.5">
                                          <p className="text-sm font-semibold text-green-500 ">
                                            Submitted to Client
                                          </p>
                                        </div>
                                      </>
                                    )}
                                    {value.schedule[0].status === "Offered" && (
                                      <>
                                        <div className=" flex flex-row justify-around cursor-pointer h-8 py-1.5">
                                          <p className="text-sm font-semibold text-green-500">
                                            Offered
                                          </p>
                                        </div>
                                      </>
                                    )}
                                    {value.schedule[0].status ===
                                      "Interview" && (
                                      <>
                                        <div className=" flex flex-row justify-around cursor-pointer h-8 py-1.5">
                                          <p className="text-sm font-semibold text-green-500">
                                            Client Interview
                                          </p>
                                        </div>
                                      </>
                                    )}
                                    {value.schedule[0].status === "ToJoin" && (
                                      <>
                                        <div className=" flex flex-row justify-around cursor-pointer h-8 py-1.5">
                                          <p className="text-sm font-semibold text-green-500">
                                            To Join
                                          </p>
                                        </div>
                                      </>
                                    )}
                                    {value.schedule[0].status ===
                                      "Declined" && (
                                      <>
                                        <div className=" flex flex-row justify-around cursor-pointer h-8 py-1.5">
                                          <p className="text-sm font-semibold text-green-500">
                                            Declined
                                          </p>
                                        </div>
                                      </>
                                    )}
                                    {value.schedule[0].status === "Joined" && (
                                      <>
                                        <div className=" flex flex-row justify-around cursor-pointer h-8 py-1.5">
                                          <p className="text-sm font-semibold text-green-500">
                                            Joined
                                          </p>
                                        </div>
                                      </>
                                    )}
                                    {value.schedule[0].status !== "Joined" &&
                                      value.schedule[0].status !==
                                        "Declined" && (
                                        <div className="flex flex-col">
                                          <div className=" relative inline-block">
                                            <button
                                              onClick={() =>
                                                toggleDropdown(index)
                                              }
                                              id="dropdownDefaultButton"
                                              data-dropdown-toggle="dropdown"
                                              className="text-gray-800 font-medium text-sm px-5 py-2.5"
                                              type="button"
                                            >
                                              <BsThreeDotsVertical />
                                            </button>

                                            {openIndex === index && (
                                              <div
                                                className="z-2 inset-0 overflow-y-auto"
                                                onClick={() => setOpenIndex(-1)}
                                              >
                                                <div
                                                  id="dropdown"
                                                  className="origin-top-right absolute right-0 z-10  mt-2 bg-white divide-y divide-gray-100 rounded-lg shadow w-44  "
                                                >
                                                  <ul
                                                    className="py-2 text-sm text-gray-700 cursor-pointer"
                                                    aria-labelledby="dropdownDefaultButton"
                                                  >
                                                    <li>
                                                      <a
                                                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600"
                                                        onClick={() => {
                                                          setScheduleId(
                                                            value.schedule[0].id
                                                          );
                                                          setSelectedType(
                                                            "Submitted"
                                                          );
                                                          setMoveNextModal(
                                                            true
                                                          );
                                                        }}
                                                      >
                                                        Client Submit
                                                      </a>
                                                    </li>
                                                    <li>
                                                      <a
                                                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600"
                                                        onClick={() => {
                                                          setScheduleId(
                                                            value.schedule[0].id
                                                          );
                                                          setSelectedType(
                                                            "Interview"
                                                          );
                                                          setMoveNextModal(
                                                            true
                                                          );
                                                        }}
                                                      >
                                                        Client Interview
                                                      </a>
                                                    </li>
                                                    <li>
                                                      <a
                                                        onClick={() => {
                                                          setScheduleId(
                                                            value.schedule[0].id
                                                          );
                                                          setSelectedType(
                                                            "Offered"
                                                          );
                                                          setMoveNextModal(
                                                            true
                                                          );
                                                        }}
                                                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600"
                                                      >
                                                        Offered
                                                      </a>
                                                    </li>
                                                    <li>
                                                      <a
                                                        onClick={() => {
                                                          setScheduleId(
                                                            value.schedule[0].id
                                                          );
                                                          setSelectedType(
                                                            "ToJoin"
                                                          );
                                                          setMoveNextModal(
                                                            true
                                                          );
                                                        }}
                                                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600"
                                                      >
                                                        To Join
                                                      </a>
                                                    </li>
                                                    <li>
                                                      <a
                                                        onClick={() => {
                                                          setScheduleId(
                                                            value.schedule[0].id
                                                          );
                                                          setSelectedType(
                                                            "Declined"
                                                          );
                                                          setMoveNextModal(
                                                            true
                                                          );
                                                        }}
                                                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600"
                                                      >
                                                        Declined
                                                      </a>
                                                    </li>
                                                    <li>
                                                      <a
                                                        onClick={() => {
                                                          setScheduleId(
                                                            value.schedule[0].id
                                                          );
                                                          setSelectedType(
                                                            "Joined"
                                                          );
                                                          setMoveNextModal(
                                                            true
                                                          );
                                                        }}
                                                        className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600"
                                                      >
                                                        Joined
                                                      </a>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      )}
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AvailabilityModal
        isOpen={manualAvailabilityModal}
        onClose={() => setManualAvailabilityModal(false)}
        onSubmit={handleManualAvailability}
        timezoneList={timezone}
        cancelReason={cancelReason}
        reason={reason}
        setReason={setReason}
        reasonCmd={reasonCmd}
        setReasonCmd={setReasonCmd}
        isReschedule={isReschedule}
        rescheduleData={rescheduleData}
        setRescheduleData={setRescheduleData}
      />
      <Modal
        isOpen={cancelScheduleModal}
        onClose={() => setCancelScheduleModal(false)}
        title="Cancel Schedule"
      >
        <div className="flex flex-col text-sm gap-4">
          <p className="">Are you sure you want to cancel this schedule?</p>
          <div className="flex items-center justify-end gap-2">
            <button
              className="bg-white text-gray-800 border px-3 py-1  rounded"
              onClick={() => setCancelScheduleModal(false)}
            >
              Cancel
            </button>
            <button
              className="bg-primary text-white px-3 py-1  rounded "
              onClick={() => {
                deleteCandidate(schedule_id);
                setCancelScheduleModal(false);
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
