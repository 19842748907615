import { FC, ReactNode } from "react";
import { IoClose } from "react-icons/io5";

interface ModalProps {
  isOpen: boolean;
  children: ReactNode;
  onClose: () => void;
  title?: string;
}

const Modal: FC<ModalProps> = ({ children, onClose, title, isOpen }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="fixed inset-0 w-full h-full bg-black opacity-50" />
      <div className="flex items-center justify-center min-h-screen">
        <div className="relative w-full max-w-lg p-4 mx-auto bg-white shadow-lg rounded-sm rounded-br-3xl">
          <div className="flex items-center justify-between">
            <h6 className="text-lg font-bold text-gray-900 mb-2">{title}</h6>
            <button
              onClick={onClose}
              type="button"
              className="text-gray-600 hover:bg-gray-200 hover:text-gray-900 rounded-lg w-8 h-8 transition-all duration-200 flex justify-center items-center"
            >
              <IoClose size={20} />
            </button>
          </div>
          <div className="mt-1">{children}</div>
        </div>
      </div>
    </div>
  );
};

export { Modal };
