import React, { ChangeEvent, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "../../../../component/common";

interface CancelReason {
  id: string;
  name: string;
}

interface Timezone {
  id: number;
  fullName: string;
  name: string;
}
interface PlaceProps {
  address: string;
  location: string;
}

interface RescheduleData {
  event_interviewer: string;
  event_org: string;
}
interface AvailabilityModalProps {
  isOpen: boolean;
  onClose: () => void;
  timezoneList: Timezone[];
  onSubmit: (data: {
    date: string;
    timezone: string;
    invitees: string[];
  }) => void;
  cancelReason: CancelReason[];
  reason: string;
  setReason: (reason: string) => void;
  reasonCmd: string;
  setReasonCmd: (reasonCmd: string) => void;
  isReschedule: boolean;
  rescheduleData: RescheduleData;
  setRescheduleData: (data: RescheduleData) => void;
}

const AvailabilityModal: React.FC<AvailabilityModalProps> = ({
  isOpen,
  onClose,
  timezoneList,
  onSubmit,
  cancelReason,
  reason,
  setReason,
  reasonCmd,
  setReasonCmd,
  isReschedule,
  rescheduleData,
  setRescheduleData,
}) => {
  const [availableDateTime, setAvailableDateTime] = useState<Date | null>(null);
  const [selectedTimezone, setSelectedTimezone] = useState<string>(
    timezoneList[0].fullName
  );
  const [inviteeEmail, setInviteeEmail] = useState<string>("");
  const [invitees, setInvitees] = useState<string[]>([]);
  const [datetimeError, setDatetimeError] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [placeError, setPlaceError] = useState<PlaceProps>({
    address: "",
    location: "",
  });

  const handleDateChange = (date: Date | null) => setAvailableDateTime(date);

  const handleTimezoneChange = (e: ChangeEvent<HTMLSelectElement>) =>
    setSelectedTimezone(e.target.value);

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInviteeEmail(e.target.value);
    setEmailError("");
  };

  const handleAddEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(inviteeEmail)) {
      const newInvitees = [...invitees, inviteeEmail];
      setInvitees(newInvitees);
      setInviteeEmail("");
      return newInvitees;
    } else {
      setEmailError("Enter a valid email address");
    }
  };

  const handleRemoveEmail = (email: string) =>
    setInvitees(invitees.filter((e) => e !== email));

  const handleSubmit = () => {
    if (!availableDateTime) {
      setDatetimeError("Please select a date and time.");
      return;
    }

    if (!selectedTimezone) {
      setDatetimeError("Please select a timezone.");
      return;
    }
    if (!rescheduleData.event_org) {
      setPlaceError({ ...placeError, address: "Please enter an address." });
      return;
    }
    if (!rescheduleData.event_interviewer) {
      setPlaceError({ ...placeError, location: "Please enter a location." });
      return;
    }

    onSubmit({
      date: availableDateTime.toISOString(),
      timezone: selectedTimezone,
      invitees: invitees,
    });

    setInvitees([]);

    setAvailableDateTime(null);
    setDatetimeError("");
    setEmailError("");
    setPlaceError({ address: "", location: "" });
    onClose();
  };

  const renderCancelReason = () => (
    <div className="col-span-2 flex flex-col gap-4">
      <select
        id="reason"
        name="reason"
        placeholder="Reason"
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        className="w-full border border-gray-200 py-2 px-3 focus:outline-none focus:border-gray-300 rounded mt-4"
      >
        <option value="">Select Reason*</option>
        {cancelReason.map((x) => (
          <option key={x.id} value={x.id}>
            {x.name}
          </option>
        ))}
      </select>
      <textarea
        placeholder="Enter the Comments"
        className="w-full border py-2 px-3 outline-none rounded"
        onChange={(e) => setReasonCmd(e.target.value)}
        value={reasonCmd}
      />
    </div>
  );

  if (!isOpen) return null;
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={isReschedule ? "Reschedule" : "Set Availability"}
    >
      <div className="grid grid-cols-2 gap-4 text-sm">
        {isReschedule && renderCancelReason()}
        {/* Date and Time Selection */}
        <div className="">
          <label className="block text-gray-700 mb-2">
            Select Date <b className="text-red-600">*</b>
          </label>
          <DatePicker
            selected={availableDateTime}
            onChange={handleDateChange}
            dateFormat="yyyy-MM-dd HH:mm:ss"
            showTimeSelect
            timeFormat="hh:mm a"
            timeIntervals={30}
            minDate={new Date()}
            className="w-full px-3 py-2 border rounded-md"
            placeholderText="Select date and time"
          />
          {datetimeError && (
            <div className="text-red-600 text-xs mt-1">{datetimeError}</div>
          )}
        </div>

        {/* Timezone Selection */}
        <div className="">
          <label className="block text-gray-700 mb-2">
            Select Time Zone <b className="text-red-600">*</b>
          </label>
          <select
            onChange={handleTimezoneChange}
            value={selectedTimezone}
            className="w-full px-3 py-2 border rounded-md"
          >
            <option value="" disabled>
              Select timezone
            </option>
            {timezoneList.map((tz) => (
              <option key={tz.fullName} value={tz.fullName}>
                {tz.name}
              </option>
            ))}
          </select>
        </div>
        <div className="">
          <label className="block text-gray-700 mb-2">
            Address <b className="text-red-600">*</b>
          </label>
          <input
            type="text"
            name="address"
            value={rescheduleData.event_org || ""}
            onChange={(e) =>
              setRescheduleData({
                ...rescheduleData,
                event_org: e.target.value,
              })
            }
            placeholder="Enter Address"
            className="w-full px-3 py-2 border rounded-md"
          />
          {placeError.address && (
            <div className="text-red-600 text-xs mt-1">
              {placeError.address}
            </div>
          )}
        </div>
        <div className="">
          <label className="block text-gray-700 mb-2">
            Location <b className="text-red-600">*</b>
          </label>
          <input
            type="text"
            name="location"
            value={rescheduleData.event_interviewer || ""}
            onChange={(e) =>
              setRescheduleData({
                ...rescheduleData,
                event_interviewer: e.target.value,
              })
            }
            placeholder="Enter Location"
            className="w-full px-3 py-2 border rounded-md"
          />
          {placeError.location && (
            <div className="text-red-600 text-xs mt-1">
              {placeError.location}
            </div>
          )}
        </div>

        {/* Email Invitee Input */}
        <div className="col-span-2">
          <label className="block text-gray-700 mb-2">Add Invitee</label>
          <input
            type="email"
            value={inviteeEmail}
            onChange={handleEmailChange}
            onKeyDown={(e) => e.key === "Enter" && handleAddEmail()}
            placeholder="Enter Email"
            className="w-full px-3 py-2 border rounded-md"
          />
          {emailError && (
            <div className="text-red-600 text-xs mt-1">{emailError}</div>
          )}
          <div className="mt-2">
            {invitees.map((email, index) => (
              <div
                key={index}
                className="flex items-center justify-between mt-2"
              >
                <span className="text-xs text-gray-600">{email}</span>
                <button
                  className="text-xs text-red-500"
                  onClick={() => handleRemoveEmail(email)}
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="mt-4 flex justify-end gap-4">
        <button
          onClick={onClose}
          className="py-2 px-4 text-gray-700 border border-gray-300 rounded-md"
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          className="py-2 px-4 text-white bg-blue-600 rounded-md"
        >
          Submit
        </button>
      </div>
    </Modal>
  );
};

export { AvailabilityModal };
